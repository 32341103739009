import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet";

function Home() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const responsive2 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="boxed_wrapper">
        <Helmet>
          <title>The Oakwood Resorts | Resort in Tadoba | Hotels in Tadoba</title>
          <meta name="description" content="The Oakwood Resorts is situated near the renowned Tadoba Andhari Tiger Reserve Located in the Chandrapur. It offers luxurious pool, spacious cottages, and delicious food. Enjoy your jungle safari adventure with us." />
          <meta name="keywords " content="The Oakwood Resorts, Best resorts in tadoba, Resorts near Tadoba Andhari National Park, Resorts near tadoba ,Hotels in tadoba, Jungle safari and resorts tadoba, Tadoba jungle safari and resorts ,Luxury resorts in tadoba, Resorts in tadoba Navegaon ,Resorts and Jungle safari in tadoba, Best Tadoba Forest Resort." />
        </Helmet>
      {/* <!-- banner-section --> */}
      <section className="banner-section centred p_relative">
        <div id="wowslider-container1">
          <div className="ws_images">
            <ul>
              <li>
                <img
                  src="/assets/images/banner/3.png"
                  alt="The Oakwood Resort"
                  title=""
                  id="wows1_0"
                  style={{ padding: "0px" }}
                />
              </li>
              <li>
                <img
                  src="/assets/images/banner/2.png"
                  alt="The Oakwood Resort"
                  title=""
                  id="wows1_0"
                  style={{ padding: "0px" }}
                />
              </li>
              <li>
                <img
                  src="/assets/images/banner/1.png"
                  alt="The Oakwood Resort"
                  title=""
                  id="wows1_1"
                  style={{ padding: "0px" }}
                />
              </li>
            </ul>
          </div>
          <div className="ws_bullets">
            <div>
              <a href="#" title="">
                <span>1</span>
              </a>
              <a href="#" title="">
                <span>2</span>
              </a>
              <a href="#" title="">
                <span>3</span>
              </a>
            </div>
          </div>
        </div>
        {/* <script type="text/javascript" src="/assets/engine1/wowslider.js"></script> */}
        <script type="text/javascript" src="/assets/engine1/script.js"></script>
      </section>
      {/* <!-- banner-section end --> */}

      {/* <!-- about-section --> */}
      <section className="about-section p_relative pt_50 pb_70">
        <div className="auto-container">
          <div className="row align-items-center clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_1">
                <div className="content-box p_relative d_block mr_70">
                  <div className="sec-title mb_35">
                    <span className="sub-title">About Us</span>
                    <h1>The Oakwood Resort</h1>
                  </div>
                  <div className="text p_relative d_block mb_40 text-justify">
                    <p>
                      Oakwood Resorts is situated near the renowned Tadoba
                      Andhari Tiger Reserve Located in the Chandrapur district
                      of Maharashtra. This reserve is recognized as one of
                      India's top tiger reserves, with its tropical dry
                      deciduous forest supporting a population of over 80 tigers
                      and more than 200 tigers in the wider landscape. Tadoba is
                      also home to various carnivore species, including leopards
                      and dholes, with the sambar, chital, wild pig, and gaur
                      serving as common prey.
                    </p>
                    <br />
                    <p>
                      Explore Tadoba's rich history, biodiversity, and
                      successful conservation efforts that have contributed to
                      the resurgence of tigers and other wildlife. Visit us at
                      Navegaon Gate, Tadoba Andhari Tiger Reserve, Khadsangi,
                      Maharashtra.
                    </p>
                  </div>
                  <div className="btn-box p_relative d_block">
                    <a href="/aboutus" className="theme-btn btn-one">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 inner-column">
              <div className="inner-content centred p_relative">
                <div className="pattern-layer">
                  <div className="pattern-1 p_absolute"></div>
                  <div
                    className="pattern-2 p_absolute wow slideInRight animated"
                    data-wow-delay="00ms"
                    data-wow-duration="1500ms"
                    style={{
                      backgroundImage:
                        "url(" + "assets/images/shape/shape-3.png" + ")",
                    }}
                  ></div>
                  <div
                    className="pattern-3 p_absolute wow slideInLeft animated"
                    data-wow-delay="00ms"
                    data-wow-duration="1500ms"
                    style={{
                      backgroundImage:
                        "url(" + "assets/images/shape/shape-4.png" + ")",
                    }}
                  ></div>
                </div>
                <img
                  src="assets/images/gallery/gallery12.jpg"
                  className="b_radius_10"
                  alt="The Oakwood Resort"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- about-section end --> */}

      {/* <!-- chooseus-section --> */}
      <section className="chooseus-section p_relative">
        <div
          className="pattern-layer p_absolute"
          style={{
            backgroundImage: "url(" + "assets/images/shape/shape-7.png" + ")",
          }}
        ></div>
        <div className="auto-container">
          <div className="inner-container p_relative d_block bg_white b_radius_5 b_shadow_6">
            <div className="shape-layer">
              <div
                className="shape-1 p_absolute"
                style={{
                  backgroundImage:
                    "url(" + "assets/images/shape/shape-5.png" + ")",
                }}
              ></div>
              <div
                className="shape-2 p_absolute"
                style={{
                  backgroundImage:
                    "url(" + "assets/images/shape/shape-6.png" + ")",
                }}
              ></div>
            </div>
            <div className="row clearfix">
              <div className="col-lg-5 col-md-12 col-sm-12 image-column">
                <figure className="image-box p_relative d_block img_hover_3">
                  <img src="assets/images/resource/Tadoba-Tiger.webp" alt="The Oakwood Resort" />
                </figure>
              </div>
              <div className="col-lg-7 col-md-12 col-sm-12 content-column">
                <div className="content_block_2">
                  <div className="content-box p_relative d_block pt_30 pl_40 pr_70 pb_20">
                    <div className="sec-title mb_10">
                      <span className="sub-title">Oakwood Resort</span>
                      <h2>Why Choose Us.</h2>
                    </div>
                    <div className="text p_relative d_block mb_35">
                      <p>
                        Resorts often offer luxurious amenities such as pools,
                        spas, restaurants, and entertainment options, making it
                        a great choice for those seeking a luxurious vacation
                        experience.
                      </p>
                    </div>
                    <div className="inner-box p_relative d_block">
                      <div className="row clearfix">
                        <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                          <div className="single-item p_relative d_block mb_25 pl_55">
                            <div className="icon-box p_absolute d_iblock fs_35">
                              <i className="icon-7"></i>
                            </div>
                            <h5 className="fs_18 lh_30 mb_5 fw_medium">
                              No Hidden Cost
                            </h5>
                            <p>No hidden charges for any free service.</p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                          <div className="single-item p_relative d_block mb_25 pl_55">
                            <div className="icon-box p_absolute d_iblock fs_35">
                              <i className="icon-8"></i>
                            </div>
                            <h5 className="fs_18 lh_30 mb_5 fw_medium">
                              Best Facilities
                            </h5>
                            <p>24-hour room service for guests.</p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                          <div className="single-item p_relative d_block mb_25 pl_55">
                            <div className="icon-box p_absolute d_iblock fs_35">
                              <i className="icon-9"></i>
                            </div>
                            <h5 className="fs_18 lh_30 mb_5 fw_medium">
                              Available 24/7
                            </h5>
                            <p>Available 24/7 Costumer Support.</p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                          <div className="single-item p_relative d_block mb_25 pl_55">
                            <div className="icon-box p_absolute d_iblock fs_35">
                              <i className="icon-10"></i>
                            </div>
                            <h5 className="fs_18 lh_30 mb_5 fw_medium">
                              Fresh Environment
                            </h5>
                            <p>
                              Resorts are often located in beautiful locations.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- chooseus-section end --> */}

      {/* <!-- service-section --> */}
      <section className="service-section p_relative bg_color_1 sec-pad centred">
        <div className="auto-container">
          <div className="sec-title mb_35">
            <span className="sub-title">Services</span>
            <h2>Services We Offer</h2>
          </div>

          <div className="clearfix">
            <div>
              <Carousel responsive={responsive} infinite={true}>
                <div>
                  <div className="">
                    <div
                      className="service-block-one"
                      data-wow-delay="600ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="inner-box p_relative d_block bg_white b_radius_5 pt_20 pl_10 pr_10 pb_20 tran_5">
                        <div className="image-box p_relative d_block mb_15">
                          <figure className="image p_relative d_iblock b_radius_50 img_hover_1">
                            <img src="assets/images/icons/massage.png" alt="The Oakwood Resort" />
                          </figure>
                        </div>
                        <h3 className="p_relative d_block fs_20 fw_medium mb_17 ml_20">
                          <a className="d_iblock">Spa</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="">
                    <div
                      className="service-block-one wow fadeInUp animated"
                      data-wow-delay="600ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="inner-box p_relative d_block bg_white b_radius_5 pt_20 pl_10 pr_10 pb_20 tran_5">
                        <div className="image-box p_relative d_block mb_15">
                          <figure className="image p_relative d_iblock b_radius_50 img_hover_1">
                            <img
                              src="assets/images/icons/swimming-pool.png"
                              alt="The Oakwood Resort"
                            />
                          </figure>
                        </div>
                        <h3 className="p_relative d_block fs_20 fw_medium mb_17">
                          <a className="d_iblock">Swimming Pool</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="">
                    <div
                      className="service-block-one wow fadeInUp animated"
                      data-wow-delay="600ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="inner-box p_relative d_block bg_white b_radius_5 pt_20 pl_10 pr_10 pb_20 tran_5">
                        <div className="image-box p_relative d_block mb_15">
                          <figure className="image p_relative d_iblock b_radius_50 img_hover_1">
                            <img src="assets/images/icons/wifi.png" alt="The Oakwood Resort" />
                          </figure>
                        </div>
                        <h3 className="p_relative d_block fs_20 fw_medium mb_17 ml_20">
                          <a className="d_iblock">Wifi</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="">
                    <div
                      className="service-block-one wow fadeInUp animated"
                      data-wow-delay="600ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="inner-box p_relative d_block bg_white b_radius_5 pt_20 pl_10 pr_10 pb_20 tran_5">
                        <div className="image-box p_relative d_block mb_15">
                          <figure className="image p_relative d_iblock b_radius_50 img_hover_1">
                            <img
                              src="assets/images/icons/smart-tv.png"
                              alt="The Oakwood Resort"
                            />
                          </figure>
                        </div>
                        <h3 className="p_relative d_block fs_20 fw_medium mb_17 ml_20">
                          <a className="d_iblock">T.V.</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="">
                    <div
                      className="service-block-one wow fadeInUp animated"
                      data-wow-delay="600ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="inner-box p_relative d_block bg_white b_radius_5 pt_20 pl_10 pr_10 pb_20 ">
                        <div className="image-box p_relative d_block mb_15">
                          <figure className="image p_relative d_iblock b_radius_50 img_hover_1">
                            <img
                              src="assets/images/icons/air-conditioner.png"
                              alt="The Oakwood Resort"
                            />
                          </figure>
                        </div>
                        <h3 className="p_relative d_block fs_20 fw_medium mb_17 ml_20">
                          <a className="d_iblock">A.C.</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="">
                    <div
                      className="service-block-one wow fadeInUp animated"
                      data-wow-delay="600ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="inner-box p_relative d_block bg_white b_radius_5 pt_20 pl_10 pr_10 pb_20 tran_5">
                        <div className="image-box p_relative d_block mb_15">
                          <figure className="image p_relative d_iblock b_radius_50 img_hover_1 ">
                            <img
                              src="assets/images/icons/car-parking.png"
                              alt="The Oakwood Resort"
                            />
                          </figure>
                        </div>
                        <h3 className="p_relative d_block fs_20 fw_medium mb_17 ml_20">
                          <a className="d_iblock">Car Parking</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- service-section --> */}

      {/* <!-- funfact-section --> */}
      <section
        className="funfact-section p_relative pt_50 pb_50 d-none"
        style={{
          backgroundImage:
            "url(" + "assets/images/background/funfact-bg.jpg" + ")",
        }}
      >
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 counter-block">
              <div
                className="counter-block-one wow slideInUp animated"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box p_relative d_block pl_95">
                  <div className="icon-box p_absolute d_iblock fs_40 bg_white b_radius_50 theme_color">
                    <i className="icon-13"></i>
                  </div>
                  <div className="count-outer count-box p_relative d_block fs_45 lh_70 color_white">
                    <span
                      className="count-text"
                      data-speed="1500"
                      data-stop="90"
                    >
                      10
                    </span>
                  </div>
                  <h6 className="p_relative d_block fs_16 lh_20 fw_medium color_white">
                    Total Rooms
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 counter-block">
              <div
                className="counter-block-one wow slideInUp animated"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box p_relative d_block pl_95">
                  <div className="icon-box p_absolute d_iblock fs_40 bg_white b_radius_50 theme_color">
                    <i className="icon-16"></i>
                  </div>
                  <div className="count-outer count-box p_relative d_block fs_45 lh_70 color_white">
                    <span
                      className="count-text"
                      data-speed="1500"
                      data-stop="216"
                    >
                      200
                    </span>
                  </div>
                  <h6 className="p_relative d_block fs_16 lh_20 fw_medium color_white">
                    Satisfied Clients
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 counter-block">
              <div
                className="counter-block-one wow slideInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box p_relative d_block pl_95">
                  <div className="icon-box p_absolute d_iblock fs_40 bg_white b_radius_50 theme_color">
                    <i className="icon-6"></i>
                  </div>
                  <div className="count-outer count-box p_relative d_block fs_45 lh_70 color_white">
                    <span
                      className="count-text"
                      data-speed="1500"
                      data-stop="35"
                    >
                      35
                    </span>
                  </div>
                  <h6 className="p_relative d_block fs_16 lh_20 fw_medium color_white">
                    Experienced Staff
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 counter-block">
              <div
                className="counter-block-one wow slideInUp animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box p_relative d_block pl_95">
                  <div className="icon-box p_absolute d_iblock fs_40 bg_white b_radius_50 theme_color">
                    <i className="icon-15"></i>
                  </div>
                  <div className="count-outer count-box p_relative d_block fs_45 lh_70 color_white">
                    <span
                      className="count-text"
                      data-speed="1500"
                      data-stop="10"
                    >
                      4
                    </span>
                  </div>
                  <h6 className="p_relative d_block fs_16 lh_20 fw_medium color_white">
                    Average Rating
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- funfact-section end --> */}

      {/* <!-- gallery-page-section --> */}
      <section className="gallery-section pt_50 pb_50">
        <div className="auto-container">
          <div className="sec-title centred mb_45">
            <span className="sub-title">Gallery</span>
            <h2>Photo Gallery</h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-lg-3 col-12 img_hover_3">
              <a
                href="assets/images/gallery/gallery1.jpg"
                data-fancybox="gallery"
                data-caption=""
              >
                <img
                  src="assets/images/gallery/gallery1.jpg"
                  alt="The Oakwood Resort Image Gallery"
                />
              </a>
            </div>
            <div className="col-lg-4 col-lg-3 col-12 img_hover_3">
              <a
                href="assets/images/gallery/gallery2.jpg"
                data-fancybox="gallery"
                data-caption=""
              >
                <img
                  src="assets/images/gallery/gallery2.jpg"
                  alt="The Oakwood Resort Image Gallery"
                />
              </a>
            </div>
            <div className="col-lg-4 col-lg-3 col-12 img_hover_3">
              <a
                href="assets/images/gallery/gallery12.jpg"
                data-fancybox="gallery"
                data-caption=""
              >
                <img
                  src="assets/images/gallery/gallery12.jpg"
                  alt="The Oakwood Resort Image Gallery"
                />
              </a>
            </div>
            {/* <div className="col-lg-3 col-lg-3 col-12 img_hover_3">
              <a
                href="assets/images/gallery/gallery3.jpg"
                data-fancybox="gallery"
                data-caption=""
              >
                <img
                  src="assets/images/gallery/gallery1.jpg"
                  alt="The Oakwood Resort Image Gallery"
                />
              </a>
            </div> */}
          </div>
          <div className="btn-box p_relative d_block centred pt_50">
            <a href="/gallery" className="theme-btn btn-one">
              More Photos
            </a>
          </div>
        </div>
      </section>
      {/* <!-- gallery-page-section end --> */}

      {/* <!-- testimonial-section --> */}

      <section className="testimonial-section p_relative sec-pad d-none">
        <div
          className="pattern-layer p_absolute wow slideInLeft animated animated"
          data-wow-delay="00ms"
          data-wow-duration="1500ms"
          style={{
            backgroundImage: "url(" + "assets/images/shape/shape-9.png" + ")",
          }}
        ></div>
        <div className="auto-container">
          <div className="clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12 mb_45">
              <div className="sec-title centred">
                <span className="sub-title">Testimonials</span>
                <h2>Happy Customer</h2>
              </div>
            </div>

            <Carousel responsive={responsive2} infinite={true}>
              <div>
                <div className="col-sm-12 testimonial-block">
              <div className="testimonial-block-one">
                <div className="inner-box p_relative d_block pl_50 pt_45 pr_30 pb_50 b_radius_5 b_shadow_6 bg_white">
                  <div
                    className="shape p_absolute"
                    style={{
                      backgroundImage:
                        "url(" + "assets/images/shape/shape-8.png" + ")",
                    }}
                  ></div>
                  <div className="text p_relative d_block mb_10">
                    <p>
                      Excellent property with warm hospitality from each and
                      every one at the resort. Complete value for money
                      considering the service and the proximity to the Navegaon
                      gate of Tadoba.
                    </p>
                  </div>
                  <ul className="rating clearfix p_relative d_block mb_20">
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                  </ul>
                  <div className="author-box p_relative d_block pl_65">
                    <figure className="author-thumb p_absolute">
                      <img
                        src="assets/images/resource/testimonial-1.jpg"
                        alt="The Oakwood Resort"
                      />
                    </figure>
                    <h5 className="p_relative d_block fs_20 lh_30 fw_medium">
                      Nicolas Lawson
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            </div>
              <div>
              <div className="col-sm-12 testimonial-block">
              <div className="testimonial-block-one">
                <div className="inner-box p_relative d_block pl_50 pt_45 pr_30 pb_50 b_radius_5 b_shadow_6 bg_white">
                  <div
                    className="shape p_absolute"
                    style={{
                      backgroundImage:
                        "url(" + "assets/images/shape/shape-8.png" + ")",
                    }}
                  ></div>
                  <div className="text p_relative d_block mb_10">
                    <p>
                      The most outstanding thing about the oakwood resort is the
                      staff, all the staff including the restaurant team,
                      reception, housekeeping, gardeners and security.
                    </p>
                  </div>
                  <ul className="rating clearfix p_relative d_block mb_20">
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                  </ul>
                  <div className="author-box p_relative d_block pl_65">
                    <figure className="author-thumb p_absolute">
                      <img
                        src="assets/images/resource/testimonial-2.jpg"
                        alt="The Oakwood Resort"
                      />
                    </figure>
                    <h5 className="p_relative d_block fs_20 lh_30 fw_medium">
                      Eva Green
                    </h5>
                  </div>
                </div>
              </div>
            </div>
              </div>
              <div>
              <div className="col-sm-12 testimonial-block">
              <div className="testimonial-block-one">
                <div className="inner-box p_relative d_block pl_50 pt_45 pr_30 pb_50 b_radius_5 b_shadow_6 bg_white">
                  <div
                    className="shape p_absolute"
                    style={{
                      backgroundImage:
                        "url(" + "assets/images/shape/shape-8.png" + ")",
                    }}
                  ></div>
                  <div className="text p_relative d_block mb_10">
                    <p>
                      Stayed at the Oakwood resort for three nights. It is very
                      close to the Navegaon Gate of Tadoba Tiger Reserve.
                      Recommended for a very quiet and peaceful stay with
                      beautiful nature.
                    </p>
                  </div>
                  <ul className="rating clearfix p_relative d_block mb_20">
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                  </ul>
                  <div className="author-box p_relative d_block pl_65">
                    <figure className="author-thumb p_absolute">
                      <img
                        src="assets/images/resource/testimonial-1.jpg"
                        alt="The Oakwood Resort"
                      />
                    </figure>
                    <h5 className="p_relative d_block fs_20 lh_30 fw_medium">
                      Sidney Poitier
                    </h5>
                  </div>
                </div>
              </div>
            </div>
              </div>
              <div>
              <div className="col-sm-12 testimonial-block">
              <div className="testimonial-block-one">
                <div className="inner-box p_relative d_block pl_50 pt_45 pr_30 pb_50 b_radius_5 b_shadow_6 bg_white">
                  <div
                    className="shape p_absolute"
                    style={{
                      backgroundImage:
                        "url(" + "assets/images/shape/shape-8.png" + ")",
                    }}
                  ></div>
                  <div className="text p_relative d_block mb_10">
                    <p>
                      Stayed at the Oakwood resort for three nights. It is very
                      close to the Navegaon Gate of Tadoba Tiger Reserve.
                      Recommended for a very quiet and peaceful stay with
                      beautiful nature.
                    </p>
                  </div>
                  <ul className="rating clearfix p_relative d_block mb_20">
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                    <li className="p_relative d_iblock float_left fs_16 mr_4">
                      <i className="icon-25"></i>
                    </li>
                  </ul>
                  <div className="author-box p_relative d_block pl_65">
                    <figure className="author-thumb p_absolute">
                      <img
                        src="assets/images/resource/testimonial-1.jpg"
                        alt="The Oakwood Resort"
                      />
                    </figure>
                    <h5 className="p_relative d_block fs_20 lh_30 fw_medium">
                      Rahul Singh
                    </h5>
                  </div>
                </div>
              </div>
            </div>
              </div>
            </Carousel>

            
            
            
          </div>
        </div>
      </section>
      {/* <!-- testimonial-section end --> */}

      {/* <!-- news-section --> */}
        <section class="news-section p_relative sec-pad">
            <div class="auto-container">
                <div class="sec-title mb_55 centred">
                    <span class="sub-title">Blogs</span>
                    <h2>Our Latest Blogs</h2>
                </div>
                <div class="row clearfix">

                {/* Blog 1  */}
                <div class="col-lg-4 col-md-6 col-sm-12 news-block">
                        <div class="news-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <div class="inner-box p_relative d_block b_radius_5 b_shadow_7 bg_white">
                                <figure class="p_relative d_block"><a href="top-5-jungles-to-visit-during-the-rainy-season"><img src="assets/images/blogs/top-5-jungles-to-visit-during-the-rainy-season.jpg" alt="blogs-image"/></a></figure>
                                <div class="lower-content p_relative d_block pl_40 pt_35 pr_30 pb_40">
                                    <span class="post-date p_relative d_block fs_15 mb_5">July 17, 2024</span>
                                    <h3 class="p_relative d_block fs_20 lh_28 mb_5 fw_medium"><a href="top-5-jungles-to-visit-during-the-rainy-season" class="d_iblock hov_color">Top 5 Jungles to Visit This Year during the Rainy Season in Tadoba National Park</a></h3>
                                    
                                    <p class="fs_15 lh_26 mb_15">Visiting a jungle during the rainy season can be a thrilling...</p>
                                    <div class="btn-box text-center">
                                        <a href="top-5-jungles-to-visit-during-the-rainy-season" class="theme-btn btn-one">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                 </div>

                  {/* Blog 2  */}
                  <div class="col-lg-4 col-md-6 col-sm-12 news-block">
                          <div class="news-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                              <div class="inner-box p_relative d_block b_radius_5 b_shadow_7 bg_white">
                                  <figure class="p_relative d_block"><a href="the-best-time-to-visit-tadoba-jungle-july-rainy-season"><img src="assets/images/blogs/the-best-time-to-visit-tadoba-jungle-july-rainy-season-1.jpg" alt="blogs-image"/></a></figure>
                                  <div class="lower-content p_relative d_block pl_40 pt_35 pr_30 pb_40">
                                      <span class="post-date p_relative d_block fs_15 mb_5">July 11, 2024</span>
                                      <h3 class="p_relative d_block fs_20 lh_28 mb_5 fw_medium"><a href="the-best-time-to-visit-tadoba-jungle-july-rainy-season" class="d_iblock hov_color">The Best Time to Visit Tadoba Jungle Exploring the Buffer Zone during the July Rainy Season</a></h3>
                                      
                                      <p class="fs_15 lh_26 mb_15">When planning a wildlife adventure...</p>
                                      <div class="btn-box text-center">
                                          <a href="the-best-time-to-visit-tadoba-jungle-july-rainy-season" class="theme-btn btn-one">Read More</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                  </div>

                  {/* Blog 3 */}
                  <div class="col-lg-4 col-md-6 col-sm-12 news-block">
                        <div class="news-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <div class="inner-box p_relative d_block b_radius_5 b_shadow_7 bg_white">
                                <figure class="p_relative d_block"><a href="why-choose-the-oakwood-resorts-in-tadoba"><img src="assets/images/blogs/why-choose-the-oakwood-resorts-for-wildlife-safari-in-tadoba.jpeg" alt="blogs-image"/></a></figure>
                                <div class="lower-content p_relative d_block pl_40 pt_35 pr_30 pb_40">
                                    <span class="post-date p_relative d_block fs_15 mb_5">July 06, 2024</span>
                                    <h3 class="p_relative d_block fs_20 lh_28 mb_5 fw_medium"><a href="why-choose-the-oakwood-resorts-in-tadoba" class="d_iblock hov_color">Why Choose The Oakwood Resorts for Wildlife Safari in Tadoba</a></h3>
                                    
                                    <p class="fs_15 lh_26 mb_15">Tadoba Andhari Tiger Reserve, nestled in the heart of Maharashtra...</p>
                                    <div class="btn-box text-center">
                                        <a href="why-choose-the-oakwood-resorts-in-tadoba" class="theme-btn btn-one">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                  </div> 

                </div>
            </div>
        </section>
        {/* <!-- news-section end --> */}

    </div>
  );
}

export default Home;
