import React from "react";
// import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";

export const CorporateAffairs = () => {
  return (
    <div>
      <Helmet>
        <title>Corporate Events at The Oakwood Resorts Tadoba | Resorts near tadoba</title>
        <meta name="description" content="The Oakwood Resorts Tadoba provides an ideal setting for corporate events, offering state-of-the-art meeting facilities & personalized service to ensure a successful gathering. You can enjoy a unique blend of professional & recreational experiences. " />
        <meta name="keyword" content="The Oakwood Resorts,Best resorts in tadoba,Resorts near Tadoba Andhari National Park,Resorts near tadoba ,Hotels in tadoba,Jungle safari and resorts tadoba,Tadoba jungle safari and resorts ,Luxury resorts in tadoba,Resorts in tadoba Navegaon ,Resorts and Jungle safari in tadoba,Best Tadoba Forest Resort"/>
      </Helmet>
      {/* <!-- Page Title --> */}
      <section
        className="page-title p_relative pt_150 pb_180 centred page-title-height"
        style={{
          backgroundImage:
            "url(" + "assets/images/resource/pench-weekend-package.jpg" + ")",
        }}
      >
        <div
          className="pattern-layer p_absolute"
          style={{
            backgroundImage: "url(" + "assets/images/shape/shape-2.png" + ")",
          }}
        ></div>
        <div className="auto-container">
          <div className="content-box">
            <h1 className="d_block fs_55 lh_70 mb_20 color_white">
              Corporate Affairs
            </h1>
            <p className="d_block fs_20 lh_30 color_white">
              {/* Cupidatat non proident */}
            </p>
          </div>
        </div>
      </section>
      {/* <!-- End Page Title --> */}

      <section class="team-details p_relative pt_100 pb_70">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="col-lg-5 col-md-12 col-sm-12 image-column">
              <div class="image-box p_relative d_block mr_20">
                <div class="image p_relative d_block b_radius_10 mb_25">
                  <img src="assets/images/services/corporate-event.jpg" alt="The Oakwood Resort" />
                </div>
                
              </div>
            </div>
            <div class="col-lg-7 col-md-12 col-sm-12 content-column">
              <div class="content-box p_relative d_block ml_20">
                <div class="text p_relative d_block mb_60">
                  <h2 class="d_block fs_25 lh_40 fw_sbold mb_3">
                    Elevate Your Corporate Events at The Oakwood Resorts Tadoba.
                  </h2>
                  <p class="d_block fs_15 lh_26 mb_25 text-justify">
                    Welcome to a world of possibilities for your next corporate event. At our resort, we specialize in creating memorable and successful gatherings that leave a lasting impression. Our state- of-the-art event spaces are designed to accommodate a wide range of corporate needs, from high-profile conferences and seminars to intimate board meetings.  
                  </p>
                  <p class="d_block fs_15 lh_26 mb_25 text-justify">
                  Our experienced event planning team is dedicated to ensuring every detail aligns with your vision. We offer customizable packages, cutting-edge audiovisual equipment, and flexible room setups to meet your specific requirements. Delectable catering options, from gourmet meals to energizing snacks, are crafted to keep your attendees engaged and satisfied. For the convenience of your guests, we provide comfortable and stylish accommodations, making it easy for them to stay on-site and maximize their time with us.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 ">
            <p class="d_block fs_15 lh_26 text-justify ml_5">Plus, our resorts's central location ensures easy access for all attendees, arriving from afar. After a productive day of meetings or workshops, unwind in our premium leisure facilities, which may include a spa, and swimming pool for team-building activities. Our commitment to excellence extends beyond the event itself; it's about creating an unforgettable experience for you and your guests. Choose our resort for your next corporate event, and let us take care of the details while you focus on achieving your business goals. Contact our dedicated team today to start planning your successful event with us. Your vision, our expertise – together, we make it happen.</p>
            </div>
                  
          </div>
        </div>
      </section>
    </div>
  );
};
