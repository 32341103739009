import React from 'react'

export const WhatsApp = () => {
  return (
    <div>   
      <a
        href="https://api.whatsapp.com/send?phone=918080898133"
        className="btn-whatsapp-pulse"
      >
        <i className="fab fa-whatsapp"></i>
      </a>
    </div>
  )
}
