import React from 'react'
import { Helmet } from "react-helmet";

export const Gallery = () => {
  return (
    <div>
        <Helmet>
          <title>Photo Gallery | The Oakwood Resorts | Best resorts in tadoba</title>
          <meta name="description" content="Discover the enchanting allure of The Oakwood Resorts through our captivating photo gallery, showcasing the stunning landscapes and luxurious accommodations. " />
          <meta name="keyword" content="The Oakwood Resorts,Best resorts in tadoba,Resorts near Tadoba Andhari National Park,Resorts near tadoba ,Hotels in tadoba,Jungle safari and resorts tadoba,Tadoba jungle safari and resorts ,Luxury resorts in tadoba,Resorts in tadoba Navegaon ,Resorts and Jungle safari in tadoba,Best Tadoba Forest Resort"/>
        </Helmet>
      {/* <!-- Page Title --> */}
      <section
        className="page-title p_relative pt_150 pb_180 centred page-title-height"
        style={{
          backgroundImage:
            "url(" + "assets/images/resource/pench-weekend-package.jpg" + ")",
        }}
      >
        <div
          className="pattern-layer p_absolute"
          style={{
            backgroundImage: "url(" + "assets/images/shape/shape-2.png" + ")",
          }}
        ></div>
        <div className="auto-container">
          <div className="content-box">
            <h1 className="d_block fs_55 lh_70 mb_20 color_white">Gallery</h1>
            <p className="d_block fs_20 lh_30 color_white">
              {/* Cupidatat non proident */}
            </p>
          </div>
        </div>
      </section>
          {/* <!-- End Page Title --> */}


          {/* galley section start */}
        <section className="gallery-section pt_50 pb_50">
            <div className="auto-container">
                <div className="sec-title centred mb_45">
                    <h2>Photo Gallery</h2>
                </div>
                {/* <div className="row">
                    <div className="col-lg-3 col- lg-3 col-12 img_hover_3">
                            <a href="assets/images/gallery/photo_gallery_1.jpg" data-fancybox="gallery" data-caption="">
                              <img src="assets/images/gallery/photo_gallery_1.jpg" alt="The Oakwood Resort"/>
                            </a>
                    </div>
                    <div className="col-lg-3 col-lg-3 col-12 img_hover_3">
                            <a href="assets/images/gallery/photo_gallery_2.jpg" data-fancybox="gallery" data-caption="">
                              <img src="assets/images/gallery/photo_gallery_2.jpg" alt="The Oakwood Resort"/>
                            </a>
                    </div>
                    <div className="col-lg-3 col-lg-3 col-12 img_hover_3">
                            <a href="assets/images/gallery/photo_gallery_3.jpg" data-fancybox="gallery" data-caption="">
                              <img src="assets/images/gallery/photo_gallery_3.jpg" alt="The Oakwood Resort"/>
                            </a>
                    </div>
                    <div className="col-lg-3 col-lg-3 col-12 img_hover_3">
                            <a href="assets/images/gallery/photo_gallery_4.jpg" data-fancybox="gallery" data-caption="">
                              <img src="assets/images/gallery/photo_gallery_4.jpg" alt="The Oakwood Resort"/>
                            </a>
                    </div>
                </div> */}
                <br />
                {/* <div className="row">
                    <div className="col-lg-3 col-lg-3 col-12 img_hover_3">
                            <a href="assets/images/gallery/photo_gallery_5.jpg" data-fancybox="gallery" data-caption="">
                              <img src="assets/images/gallery/photo_gallery_5.jpg" alt="The Oakwood Resort"/>
                            </a>
                    </div>
                    <div className="col-lg-3 col-lg-3 col-12 img_hover_3">
                            <a href="assets/images/gallery/photo_gallery_6.jpg" data-fancybox="gallery" data-caption="">
                              <img src="assets/images/gallery/photo_gallery_6.jpg" alt="The Oakwood Resort"/>
                            </a>
                    </div>
                    <div className="col-lg-3 col-lg-3 col-12 img_hover_3">
                            <a href="assets/images/gallery/photo_gallery_7.jpg" data-fancybox="gallery" data-caption="">
                              <img src="assets/images/gallery/photo_gallery_7.jpg" alt="The Oakwood Resort"/>
                            </a>
                    </div>
                    <div className="col-lg-3 col-lg-3 col-12 img_hover_3">
                            <a href="assets/images/gallery/photo_gallery_8.jpg" data-fancybox="gallery" data-caption="">
                              <img src="assets/images/gallery/photo_gallery_8.jpg" alt="The Oakwood Resort"/>
                            </a>
                    </div>
                </div> */}
                <br />
                <div className="row">
                    <div className="col-lg-3 col-lg-3 col-12 img_hover_3">
                            <a href="assets/images/gallery/gallery1.jpg" data-fancybox="gallery" data-caption="">
                              <img src="assets/images/gallery/gallery1.jpg" alt="The Oakwood Resort"/>
                            </a>
                    </div>
                    <div className="col-lg-3 col-lg-3 col-12 img_hover_3">
                            <a href="assets/images/gallery/gallery2.jpg" data-fancybox="gallery" data-caption="">
                              <img src="assets/images/gallery/gallery2.jpg" alt="The Oakwood Resort"/>
                            </a>
                    </div>
                    <div className="col-lg-3 col-lg-3 col-12 img_hover_3">
                            <a href="assets/images/gallery/gallery3.jpg" data-fancybox="gallery" data-caption="">
                              <img src="assets/images/gallery/gallery3.jpg" alt="The Oakwood Resort"/>
                            </a>
                    </div>
                    <div className="col-lg-3 col-lg-3 col-12 img_hover_3">
                            <a href="assets/images/gallery/gallery4.jpg" data-fancybox="gallery" data-caption="">
                              <img src="assets/images/gallery/gallery4.jpg" alt="The Oakwood Resort"/>
                            </a>
                    </div>
                </div>
                <br/>
                <div className="row">
                   
                    <div className="col-lg-3 col-lg-3 col-12 img_hover_3">
                            <a href="assets/images/gallery/gallery7.jpg" data-fancybox="gallery" data-caption="">
                              <img src="assets/images/gallery/gallery7.jpg" alt="The Oakwood Resort"/>
                            </a>
                    </div>
                    <div className="col-lg-3 col-lg-3 col-12 img_hover_3">
                            <a href="assets/images/gallery/gallery8.jpg" data-fancybox="gallery" data-caption="">
                              <img src="assets/images/gallery/gallery8.jpg" alt="The Oakwood Resort"/>
                            </a>
                    </div>
                    <div className="col-lg-3 col-lg-3 col-12 img_hover_3">
                            <a href="assets/images/gallery/gallery9.jpg" data-fancybox="gallery" data-caption="">
                              <img src="assets/images/gallery/gallery9.jpg" alt="The Oakwood Resort"/>
                            </a>
                    </div>
                    <div className="col-lg-3 col-lg-3 col-12 img_hover_3">
                            <a href="assets/images/gallery/gallery10.jpg" data-fancybox="gallery" data-caption="">
                              <img src="assets/images/gallery/gallery10.jpg" alt="The Oakwood Resort"/>
                            </a>
                    </div>
                </div>
                <br/>
                <div className="row">
                    
                    <div className="col-lg-3 col-lg-3 col-12 img_hover_3">
                            <a href="assets/images/gallery/gallery11.jpg" data-fancybox="gallery" data-caption="">
                              <img src="assets/images/gallery/gallery11.jpg" alt="The Oakwood Resort"/>
                            </a>
                    </div>
                    <div className="col-lg-3 col-lg-3 col-12 img_hover_3">
                            <a href="assets/images/gallery/gallery12.jpg" data-fancybox="gallery" data-caption="">
                              <img src="assets/images/gallery/gallery12.jpg" alt="The Oakwood Resort"/>
                            </a>
                    </div>
                </div>
            </div>
        </section>
          {/* gallery section end */}
    </div>
  )
}
