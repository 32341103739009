// import React from "react";
import React,{useState} from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

export const ContactUs = () => {

    const captchaSumbit = () =>{

    }

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
    //   const [successMessage, setSuccessMessage] = useState('');
    const [alertType, setAlertType] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
      

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // Send form data to PHP backend
        axios
          .post('https://the-oakwood-resorts-backend.vercel.app/api/contactUs', formData)
          .then((response) => {
            // Handle response from PHP backend
            console.log(response.data);
            // setSuccessMessage(response.data.message);
            if (response.data.success) {
                setAlertType('success');
                setAlertMessage('Form submitted successfully');
              } else {
                setAlertType('danger');
                setAlertMessage('Form submission failed');
              }
            
            // Reset form fields
            setFormData({ firstName: '', lastName: '', email: '', phoneNumber:'', message: '' });
          })
          .catch((error) => {
            console.error('Error:', error);
            setAlertType('danger');
            setAlertMessage('An error occurred');
          });
      };
  return (
    <div className="boxed_wrapper">

      <Helmet>
        <title>Contact The Oakwood Resorts – Best Resorts near Tadoba</title>
        <meta name="description" content="Contact The Oakwood Resorts on +91 8080898133 or visit our website - https://theoakwoodresorts.com/" />
        <meta name="keyword" content="The Oakwood Resorts,Best resorts in tadoba,Resorts near Tadoba Andhari National Park,Resorts near tadoba ,Hotels in tadoba,Jungle safari and resorts tadoba,Tadoba jungle safari and resorts ,Luxury resorts in tadoba,Resorts in tadoba Navegaon ,Resorts and Jungle safari in tadoba,Best Tadoba Forest Resort"/>
      </Helmet>

<section className="page-title p_relative pt_150 pb_180 centred page-title-height" style={{backgroundImage: "url("+"assets/images/resource/pench-weekend-package.jpg"+")"}}>
            <div className="pattern-layer p_absolute" style={{backgroundImage: "url("+"assets/images/shape/shape-2.png"+")"}}></div>
            <div className="auto-container">
                <div className="content-box">
                    <h1 className="d_block fs_55 lh_70 mb_20 color_white">Contact Us</h1>
                    <p className="d_block fs_20 lh_30 color_white">
                        {/* Cupidatat non proident */}
                        </p>
                </div>
            </div>
        </section>





      <section className="contact-section p_relative pt_140 pb_150">
            <div className="auto-container">
                <div className="row align-items-center clearfix">
                    <div className="col-lg-5 col-md-12 col-sm-12 info-column">
                        <div className="info-inner p_relative d_block mr_30 b_radius_5 theme_bg pt_65 pr_50 pb_100 pl_70">
                            <div className="shape p_absolute" style={{backgroundImage: "url("+"assets/images/shape/shape-39.png"+")"}}></div>
                            <h2 className="p_relative d_block fs_30 lh_40 color_white mb_30">Contact <br/>Information</h2>
                            <ul className="info-list clearfix">
                                <li className="p_relative d_block pl_70 mb_25">
                                    <div className="icon-box p_absolute b_radius_5 bg_white theme_color centred fs_22 lh_50"><i className="fas fa-map-marker-alt"></i></div>
                                    <h3 className="fs_22 lh_32 fw_medium color_white mb_5">Office Location</h3>
                                    <p className="fs_15 lh_26 color_white">Navegaon Gate, Tadoba Andhari Tiger Reserve, Khadsangi, Maharashtra 442906</p>
                                </li>
                                <li className="p_relative d_block pl_70 mb_25">
                                    <div className="icon-box p_absolute b_radius_5 bg_white theme_color centred fs_22 lh_50"><i className="fas fa-envelope-open"></i></div>
                                    <h3 className="fs_22 lh_32 fw_medium color_white mb_5">Email Drop Us</h3>
                                    <p className="fs_15 lh_26 color_white"><a href="mailto:theoakwoodresorts@gamil.com" className="color_white">theoakwoodresorts@gmail.com</a><br/><a href="mailto:theoakwoodresorts@gamil.com" className="color_white"></a></p>
                                </li>
                                <li className="p_relative d_block pl_70">
                                    <div className="icon-box p_absolute b_radius_5 bg_white theme_color centred fs_22 lh_50"><i className="fas fa-phone"></i></div>
                                    <h3 className="fs_22 lh_32 fw_medium color_white mb_5">Call for Help</h3>
                                    <p className="fs_15 lh_26 color_white">
                                      <a href="tel:+91 8080898133" className="color_white">+91 8080898133</a>
                                    <br/>
                                      {/* <a href="tel:+91 9373285702" className="color_white">+91 9373285702</a> */}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-sm-12 form-column">
                        <div className="form-inner">
                            <h2 className="p_relative d_block fs_30 lh_40 mb_30">Send us a message</h2>
                            
                            {alertMessage && (
                                <div className={`alert alert-${alertType}`} role="alert">
                                {alertMessage}
                                </div>
                            )}

<form method="post" id="contact-form" className="default-form" 
onSubmit={handleSubmit}> 
  <div className="row clearfix">
      <div className="col-lg-6 col-md-6 col-sm-12 form-group mb_30">
          <input type="text" name="firstName" placeholder="First Name" required="true" aria-required="true" value={formData.firstName} onChange={handleChange}/>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 form-group mb_30">
          <input type="text" name="lastName" placeholder="Last Name" required="true" aria-required="true" value={formData.lastName} onChange={handleChange}/>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 form-group mb_30">
          <input type="email" name="email" placeholder="Email" required="true" aria-required="true" value={formData.email} onChange={handleChange}/>
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12 form-group mb_30">
          <input type="text" name="phoneNumber" required="true" placeholder="Phone Number" aria-required="true" value={formData.phoneNumber} onChange={handleChange}/>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 form-group mb_30">
          <textarea name="message" placeholder="Your Message" required value={formData.message} onChange={handleChange}></textarea>
      </div>
      {/* <div className="col-lg-6 col-md-12 col-sm-12 form-group mb_30">
      <ReCAPTCHA sitekey="6Lfk5CMoAAAAAOcs5UX4gXf2QYvpREaeepT-tZ6y" onChange={captchaSumbit} />
      </div> */}
      <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn mr-0">
          <button className="theme-btn btn-one" type="submit" name="submit-form">Submit Now</button>
      </div>
  </div>
</form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      <div className="contactus_maps">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21759.64206214862!2d79.2566154535225!3d20.50243509788183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd34f2c8735a123%3A0xa3a4ceab18e7813c!2sThe%20Oakwood%20Resorts%2C%20Tadoba!5e0!3m2!1sen!2sin!4v1688444972440!5m2!1sen!2sin"
          width="85%"
          height="300"
          style={{ border: 0,padding:"20px"}}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};
