import React from 'react'

export const TermsOfService = () => {
  return (
    <div>
      {/* <!-- Page Title --> */}
      <section
        className="page-title p_relative pt_150 pb_180 centred page-title-height"
        style={{
          backgroundImage:
            "url(" + "assets/images/resource/pench-weekend-package.jpg" + ")",
        }}
      >
        <div
          className="pattern-layer p_absolute"
          style={{
            backgroundImage: "url(" + "assets/images/shape/shape-2.png" + ")",
          }}
        ></div>
        <div className="auto-container">
          <div className="content-box">
            <h1 className="d_block fs_55 lh_70 mb_20 color_white">Terms & Conditions</h1>
            <p className="d_block fs_20 lh_30 color_white">
              {/* Cupidatat non proident */}
            </p>
          </div>
        </div>
      </section>
      {/* <!-- End Page Title --> */}

      <div className="pt_50 pl_100 pb_30 lh_40 pr_60 text-justify">
        
        <h3 className='fw_medium mt_10' >TERMS OF USE</h3>
        <p>
          1. Hotel Standard Check-in time is 1300 hours IST and check-out time is 1100 hours.
        </p>
        <p>
          2. Early check-in and late check-out are available on subject to availability.
        </p>
        <p>
          3. Individual Cancellation, No-Show and Amendment Policy: Cancellation made 3 days prior to the arrival date shall not incur any cancellation charges ‘beyond 1 - night retention charges’ will be applicable.
        </p>
        <p>
          4. Group Cancellation (5 rooms and above): Group cancellation made 25 days prior to the arrival date shall incur cancellation charges ‘beyond 100% will be charged through length of stay.
        </p>
        <p>
          5. All standard policies pertaining to hotel/ hospitality industry are applicable, when specific reservation policy is not mentioned in the “Reservation Confirmation” document.
        </p>

        <h3 className='fw_medium mt_20'>GENERAL PROVISIONS</h3>
        <p>
          1. By proceeding with the reservation, you further agree and acknowledge that if the reservation is accepted by the Hotel, your stay subsequently shall be subject to the Hotel’s Standard Terms and Conditions in respect of their reservation / stay at the Hotel.
        </p>
        <p>
          2. You further agree that the General Terms and Conditions as provided on the website shall constitute the binding agreement between yourself and Hotel The Oakwood Resorts in relation to any and all commercial arrangement including reservation, stay, payment, usage of facilities, etc. of whatsoever nature.
        </p>
        <p>
          3. The Hotel may, at its absolute discretion, cancel the reservation i f the Hotel is of the opinion that the reservation information provided is falsified or incomplete.
        </p>
        <p>
          4. Guest voluntarily agrees and permits the Hotel representatives to profile the guest from the public domain to ascertain the details of the guest and render adequate hospitality services to them during their stay in the hotel.
        </p>
        <p>
          5. The Hotel shall be entitled to vary, amend and/or otherwise change these terms and conditions at any time without prior notice.
        </p>
        <p>
          6. You shall indemnify and hold the Hotel harmless in respect of any liability, loss, damage, cost and expense of any nature arising out of, and/or in connection with the acceptance of the reservation and your stay in the Hotel.
        </p>
        <p>
          7. The Hotel shall not be liable for any losses, damages, costs or expenses incurred by you as a result of any cancellation of the reservation by the Hotel.
        </p>
        <p>
          8. The Hotel does not accept any liability for any failure by the Hotel to comply with these conditions where such failure is due to circumstances beyond its reasonable control.
        </p>
        <p>
          9. If the Hotel waives any rights available to it under these conditions on one occasion, this does not mean that those rights will automatically be waived on any other occasion
        </p>






          <h3 className='fw_medium mt_20'>STANDARD TERMS AND CONDITIONS</h3>
        <p>
          1. No gatherings and parties of any nature are allowed in the room. The Hotel reserves the right to evict any additional occupants in the room.
        </p>
        <p>
          2. This reservation is not transferable and non-refundable
        </p>
        <p>
          3. In the event of no-show, a fee of one night’s room charge (inclusive of any applicable prevailing government tax) will be charged to your credit card provided at the time of reservation or as per any revised conditions of booking as per the offer.
        </p>
        <p>
          4. Hotel reserves the right to send guests away from the Hotel due to their conduct and behaviour or for any other safety, security, medical reasons. This applies in particular, if Guests do not observe instructions given by Hotel employees, express themselves in a discriminating manner, harass or endanger other guests.
        </p>
        <p>
          5. Guests will not be permitted to compromise the health, safety and security aspects of the Hotel at any point of time.
        </p>
        <p>
          6. Guests are bound to produce an original proof of identity at the time arrival in the Hotel 
        </p>
        <p>
          7. Special terms of offer will be applicable for the respective bookings and in case of conflict, the terms of such special offers will prevail.
        </p>
        <p>
          8. Guests are not allowed to bring food and beverage from outside in the Hotel or order food from outside the Hotel.
        </p>
        <p>
          9. The Hotel undertakes no liability for the shelf life of the food which is taken away to the Hotel after an event at the Hotel or taken outside the Hotel for private consumption.
        </p>
        <p>
          10. In case of conflict in the actual bill provided to you after the receipt of services, the terms of the bill will prevail.
        </p>
        <p>
          11. Neatness and hygiene should be adequately maintained by the Guest. The Guest should always adhere to the safety, security and hygiene advise duly
provided by the Hotel and should avoid breaching the same. The Guest will be solely responsible for any such violation if identified.
        </p>
        <p>
          12. All the assets and valuables of the Hotel should be duly maintained adequately and should not be damaged or destroyed. The Hotel has the discretion to levy any charges in future if it is proven beyond doubt that such damage/destruction to the asset of the Hotel (both movable and immovable) have been done or caused by the Guest.
        </p>
        <p>
          13. If you have any allergies, sensitivities or intolerance to, but not limited to: a particular fabric, material, cleaning product or food, it is not Hotel’s responsibility to advise Hotel management prior to arrival.
        </p>
        <p>
          14. Your valuables can be stored in a safety deposit box in your room or the hotel can store your valuables upon request. The hotel is entitled to collect a charge for storage of these items.
        </p>
        <p>
          15. In case the items you wish to store are exceptionally valuable you must notify the hotel before storing. The hotel may refuse to store this kind of valuables
        </p>
        <p>
          16. Management and staff work hard to provide a safe and secure environment. We do everything possible to ensure a secure environment is maintained and we ask that our guests do also. The Hotel takes no responsibility for any personal possessions that are lost, stolen or misplaced whilst on the premises due to the acts and omissions of the guests themselves.
        </p>
        <p>
          17. Lost property found on the premises is logged and kept in a secure location for a period of three (3) months. Thereafter items are either disposed of or donated to charity. The Hotel accepts no responsibility for contacting individuals in relation to lost property. Perishable items retrieved from rooms after check out are only held until close of business that day.
        </p>
        <p>
          18. Claimed items can be collected from the hotel with valid identification or alternatively the hotel can arrange postage on behalf of the guest at their expense.
        </p>
        <p>
          19. The Hotel is not responsible for damage or disappearance of vehicles kept in the parking area. The hotel is obliged to clearly express in the parking area that the area is not supervised and the hotel is not responsible for the property kept in there.
        </p>
        <p>
          20. Valet parking is always at the Guest’s risk and advise from the Security Team needs to be strictly adhered with by the Guest at the time of checking in. Hotel will not be accountable for loss of any valuables from such parked vehicles.
        </p>
        <p>
          21. The Company reserves the right to change these terms and conditions at any time without prior notice. In the event that any changes are made, the revised terms and conditions shall be posted on this website immediately. Please check the latest information posted herein to inform yourself of any changes.
        </p>
        <p>
          22. We do our best to ensure reservation arrangements are satisfactory, however, the Hotel does not accept any liability for any loss financial or otherwise, travel delay, injury, damage, additional expenses or inconvenience caused directly or indirectly by any events which are beyond our control. These include, but not limited to, flight delays or cancellations, civil disturbance, defects in vehicles, strikes, theft, acts of terrorism, natural disaster, war, fire, floods, acts of God, acts of Government or of any other authorities, changes to Government regulations, accident to or failure of machinery or equipment, maintenance requirements or industrial action.
        </p>
        <p>
          23. The transport to the airport is provided as an ancillary service and the Hotel is not accountable to whatsoever happens to the guests during such transits.
        </p>
      </div>
    </div>
  )
}
