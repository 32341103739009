import { Helmet } from "react-helmet";

export const Safari = () => {
  return (
    <div className="safari_container">

      <Helmet>
        <title>Jungle Safari and Resorts in Tadoba | Jungle safari Tadoba </title>
        <meta name="description" content="Embark on an exhilarating jungle safari at The Oakwood Resorts, where you'll explore the rich biodiversity of Tadoba's lush forests. Guided by experienced naturalists, witness majestic tigers, diverse wildlife, and vibrant flora in their natural habitat. " />
        <meta name="keyword" content="The Oakwood Resorts,Best resorts in tadoba,Resorts near Tadoba Andhari National Park,Resorts near tadoba ,Hotels in tadoba,Jungle safari and resorts tadoba,Tadoba jungle safari and resorts ,Luxury resorts in tadoba,Resorts in tadoba Navegaon ,Resorts and Jungle safari in tadoba,Best Tadoba Forest Resort"/>
      </Helmet>


      <section
        className="page-title p_relative pt_150 pb_180 centred page-title-height"
        style={{
          backgroundImage:
            "url(" + "assets/images/resource/pench-weekend-package.jpg" + ")",
        }}
      >
        <div
          className="pattern-layer p_absolute"
          style={{
            backgroundImage: "url(" + "assets/images/shape/shape-2.png" + ")",
          }}
        ></div>
        <div className="auto-container">
          <div className="content-box">
            <h1 className="d_block fs_55 lh_70 mb_20 color_white">Safari</h1>
            <p className="d_block fs_20 lh_30 color_white">
              {/* Cupidatat non proident */}
            </p>
          </div>
        </div>
      </section>
      {/* poster end */}
      {/* <div className="sec-title md_15 pt_20">
        <h2>Book A Safari</h2>
      </div> */}
      <section className="service-section alternat-2 p_relative centred pr_120 pl_120 pt_80">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 service-block">
              <div
                className="service-block-one wow fadeInUp animated animated"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animatioDuration: "1500ms",
                  animationDelay: "0ms",
                }}
              >
                <div className="inner-box p_relative d_block bg_white b_radius_5 b_shadow_6 tran_5 mb_30">
                  <div className="image-box p_relative d_block mb_45">
                    <figure className="image p_relative d_block img_hover_1">
                      <img
                        src="assets/images/services/normal_safari.jpg"
                        alt="The Oakwood Resort"
                      />
                    </figure>
                  </div>
                  <div className="lower-content p_relative d_block pl_20 pr_20 pb_40">
                    <h3 className="p_relative d_block fs_22 fw_medium mb_17">
                      <a
                        href="/booknow"
                        className="d_iblock"
                      >
                        Safari : Regular
                      </a>
                    </h3>
                    <p className="mb_17 lh_26">
                      Safari for Core and Buffer Gates. Tourists can book up to 120
                      days in advance.
                    </p>
                    <div className="btn-box p_relative d_block">
                      <a href="/booknow" className="theme-btn btn-one">
                        Book Now
                      </a>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 service-block">
              <div
                className="service-block-one wow fadeInUp animated animated"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animatioDuration: "1500ms",
                  animationDelay: "0ms",
                }}
              >
                <div className="inner-box p_relative d_block bg_white b_radius_5 b_shadow_6 tran_5 mb_30">
                  <div className="image-box p_relative d_block mb_45">
                    <figure className="image p_relative d_block img_hover_1">
                      <img
                        src="assets/images/services/tatkal_safari.jpg"
                        alt="The Oakwood Resort"
                      />
                    </figure>
                  </div>
                  <div className="lower-content p_relative d_block pl_20 pr_20 pb_40">
                    <h3 className="p_relative d_block fs_22 fw_medium mb_17">
                      <a
                        href="/booknow"
                        className="d_iblock"
                      >
                        Safari : Tatkal
                      </a>
                    </h3>
                    <p className="mb_17 lh_26">
                      For Core Gates only. Tatkal bookings are open 3 days prior to
                      the actual date.
                    </p>
                    <div className="btn-box p_relative d_block">
                      <a href="/booknow" className="theme-btn btn-one">
                        Book Now
                      </a>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 service-block">
              <div
                className="service-block-one wow fadeInUp animated animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animatioDuration: "1500ms",
                  animationDelay: "0ms",
                }}
              >
                <div className="inner-box p_relative d_block bg_white b_radius_5 b_shadow_6 tran_5 mb_30">
                  <div className="image-box p_relative d_block mb_45">
                    <figure className="image p_relative d_block img_hover_1">
                      <img src="assets/images/services/cantervan.jpg" alt="The Oakwood Resort" />
                    </figure>
                  </div>
                  <div className="lower-content p_relative d_block pl_20 pr_20 pb_40">
                    <h3 className="p_relative d_block fs_22 fw_medium mb_17">
                      <a
                        href="/booknow"
                        className="d_iblock"
                      >
                        Canter Bus
                      </a>
                    </h3>
                    <p className="mb_17 lh_26">
                      Canter rides are available only for Moharli Core Gate,
                      on a per-seat basis.
                    </p>
                    <div className="btn-box p_relative d_block">
                      <a href="/booknow" className="theme-btn btn-one">
                        Book Now
                      </a>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="note_container">
        <h4>Note</h4>
        <p>
          1. You can use the INTERACTIVE MAP here to see what Zone best fits
          your travel plans.
        </p>
        <p>
          2. Offline (on-the-spot) bookings can only be made at Buffer Gates and
          are subject to availability of slots.
        </p>
        <p>
          3. Visitors are required to adhere to the tiger reserve’s rules and
          regulations. Click here for the CORE and BUFFER rules.
        </p>
        <p>
          4. Click here for details about our Booking CANCELLATION and REFUND
          policies.
        </p>
      </div>
      <div>
        <div className="sec-title md_15">
          <h2>Safari Timings</h2>
        </div>
        <div className="safari_table1 table-responsive">
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">MORNING</th>
                  <th scope="col"></th>
                  <th scope="col">EVENING</th>
                  <th scope="col"></th>
                </tr>
                <tr>
                  <th scope="col">Periods</th>
                  <th scope="col">Entry</th>
                  <th scope="col">Exit</th>
                  <th scope="col">Entry</th>
                  <th scope="col">Exit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">1 - 31 Oct</td>
                  <td>06:00hrs</td>
                  <td>10:00hrs</td>
                  <td>14:30hrs</td>
                  <td>18:30hrs</td>
                </tr>
                <tr>
                  <td scope="row">1 Nov - 28/29 Feb</td>
                  <td>06:00hrs</td>
                  <td>10:00hrs</td>
                  <td>14:30hrs</td>
                  <td>18:30hrs</td>
                </tr>
                <tr>
                  <td scope="row">1 Mar - 30 Apr</td>
                  <td>06:00hrs</td>
                  <td>10:00hrs</td>
                  <td>14:30hrs</td>
                  <td>18:30hrs</td>
                </tr>
                <tr>
                  <td scope="row">1 May-30 June</td>
                  <td>06:00hrs</td>
                  <td>10:00hrs</td>
                  <td>14:30hrs</td>
                  <td>18:30hrs</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <div className="sec-title md_15 pb_10 pt_25">
          <h2>Safari Fees</h2>
        </div>
      </div>
      <div>
        <div className="sec-title md_15 pt_10 ">
          <h4>Moharli, Khutwanda, Zari, Pangadi, Kolara & Navegaon Gates</h4>
        </div>
      </div>
      <div className="safari_table1 table-responsive">
        <table className="table">
          <tbody>
            <tr className="fs_15">
              <th>FEES</th>
              <th>BOOKING IN 4-59 DAYS</th>
              <th></th>
              <th>BOOKING IN 60-120 DAYS</th>
              <th></th>
              <th>BOOKING IN 1-3 DAYS (TATKAL)</th>
            </tr>
            <tr>
              <th></th>
              <th>Monday to Friday</th>
              <th>Saturday &amp; Sunday</th>
              <th>Monday to Friday</th>
              <th>Saturday &amp; Sunday</th>
              <th>Monday to Sunday</th>
            </tr>
            <tr>
              <td>Entry </td>
              <td>₹1000</td>
              <td>₹2000</td>
              <td>₹4000</td>
              <td>₹8000</td>
              <td>₹4000</td>
            </tr>
            <tr>
              <td>Guide </td>
              <td>₹500</td>
              <td>₹500</td>
              <td>₹500</td>
              <td>₹500</td>
              <td>₹500</td>
            </tr>
            <tr>
              <td>Gypsy </td>
              <td>₹3000</td>
              <td>₹3000</td>
              <td>₹3000</td>
              <td>₹3000</td>
              <td>₹3000</td>
            </tr>
            <tr>
              <td>Processing </td>
              <td>₹75</td>
              <td>₹75</td>
              <td>₹75</td>
              <td>₹75</td>
              <td>₹75</td>
            </tr>
            <tr>
              <th>Total</th>
              <th>₹4575</th>
              <th>₹5575</th>
              <th>₹7575</th>
              <th>₹11575</th>
              <th>₹7575</th>
            </tr>
          </tbody>
        </table>
      </div>
      
      
        <div>
          <div className="sec-title md_15 pb_20">
            <h3>Night Safari</h3>
          </div>

      <div className="safari_table1 table-responsive">
          <table className="table">
            <tbody>
              <tr>
                <th>FEES</th>
                <th>BOOKING IN 1-120 DAYS</th>
              </tr>
              <tr>
                <td>Entry</td>
                <td>₹1500</td>
              </tr>
              <tr>
                <td>Guide</td>
                <td>₹500</td>
              </tr>
              <tr>
                <td>Gypsy</td>
                <td>₹2200</td>
              </tr>
              <tr>
                <td>Processing</td>
                <td>₹75</td>
              </tr>
              <tr>
                <th>Total</th>
                <th>₹4275</th>
              </tr>
            </tbody>
          </table>
        </div>
        </div>

          <div>
            <div className="sec-title md_15 pb_20">
              <h3>Full-day Photography / Videography</h3>
            </div>
          </div>
          <div>
        <div className="safari_table1 table-responsive">
            <table className="table">
              <tbody>
                <tr>
                  <th>FEES</th>
                  <th>INDIAN TOURISTS</th>
                  <th>FOREIGN TOURISTS</th>
                </tr>
                <tr>
                  <td>Ticket</td>
                  <td>One Online Ticket</td>
                  <td>One Online Ticket</td>
                </tr>
                <tr>
                  <td>Permission</td>
                  <td>₹30,000</td>
                  <td>₹45,000</td>
                </tr>
                <tr>
                  <td>Guide </td>
                  <td>₹1000</td>
                  <td>₹1000</td>
                </tr>
                <tr>
                  <td>Gypsy </td>
                  <td>₹6000</td>
                  <td>₹6000</td>
                </tr>
                <tr>
                  <td>Camera</td>
                  <td>No Charge</td>
                  <td>No Charge</td>
                </tr>
                <tr>
                  <th>Total</th>
                  <th>₹37,000</th>
                  <th>₹52,000</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="sec-title md_15 pb_20">
          <h5 className="p_20">
            Junona, Adegaon, Devada, Agarzari, Mamla, Pangadi, Aswal Chuha,
            Zari, Keslaghat, Zari Peth, Kolara Chauradeo, Alizanza, Madnapur,
            Shirkheda, Belara, Ramdegi & Nimdela Gates
          </h5>
        </div>
      <div >
        <div className="safari_table1 table-responsive">
        <table className="table">
          <tbody>
            <tr>
              <th>FEES</th>
              <th>BOOKING IN 1-120 DAYS</th>
              <th>SPOT BOOKING</th>
            </tr>
            <tr>
              <td>Entry</td>
              <td>₹1000</td>
              <td>₹2000</td>
            </tr>
            <tr>
              <td>Guide</td>
              <td>₹500</td>
              <td>₹500</td>
            </tr>
            <tr>
              <td>Gypsy</td>
              <td>₹2500</td>
              <td>₹2500</td>
            </tr>
            <tr>
              <td>Processing</td>
              <td>₹75</td>
              <td></td>
            </tr>
            <tr>
              <th>Total</th>
              <th>₹4075</th>
              <th>₹5000</th>
            </tr>
          </tbody>

        </table>
      </div>
      </div>
    </div>
  );
};
