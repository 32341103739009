import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";

export const AboutUs = () => {
  return (
    <div>
      {/* <!-- Page Title --> */}
      <Helmet>
        <title>Jungle safari and resorts in Tadoba | Best resorts in Tadoba</title>
        <meta name="description" content="The Oakwood Resorts offers best Jungle safari and resorts experience. We offer complete jungle safari package including hotel transfers, stay, meals and jungle safari." />
        <meta name="keyword" content="The Oakwood Resorts, Best resorts in tadoba, Resorts near Tadoba Andhari National Park, Resorts near tadoba ,Hotels in tadoba,Jungle safari and resorts tadoba,Tadoba jungle safari and resorts ,Luxury resorts in tadoba,Resorts in tadoba Navegaon ,Resorts and Jungle safari in tadoba,Best Tadoba Forest Resort"/>
      </Helmet>
      <section
        className="page-title p_relative pt_150 pb_180 centred page-title-height"
        style={{
          backgroundImage:
            "url(" + "assets/images/resource/pench-weekend-package.jpg" + ")",
        }}
      >
        <div
          className="pattern-layer p_absolute"
          style={{
            backgroundImage: "url(" + "assets/images/shape/shape-2.png" + ")",
          }}
        ></div>
        <div className="auto-container">
          <div className="content-box">
            <h1 className="d_block fs_55 lh_70 mb_20 color_white">About Us</h1>
            <p className="d_block fs_20 lh_30 color_white">
              {/* Cupidatat non proident */}
            </p>
          </div>
        </div>
      </section>
      {/* <!-- End Page Title --> */}

      {/* <!-- about-style-two --> */}
      <section className="about-style-two p_relative pt_100 pb_100">
        <div
          className="pattern-layer p_absolute"
          style={{
            backgroundImage: "url(" + "assets/images/shape/shape-33.png" + ")",
          }}
        ></div>
        <div className="auto-container">
          <div className="row align-items-center clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_1">
                <div className="image-box p_relative d_block pr_90 pb_40">
                  <div className="shape-1 theme_bg p_absolute b_radius_50"></div>
                  <div
                    className="shape-2 p_absolute"
                    style={{
                      backgroundImage:
                        "url(" + "assets/images/shape/shape-19.png" + ")",
                    }}
                  ></div>
                  <figure className="image p_relative d_block clearfix">
                    <img src="assets/images/resource/about-1.png" alt="The Oakwood Resort" style={{width:"430px"}}/>
                  </figure>
                  {/* <div className="inner p_absolute d_flex b_radius_50 b_shadow_7 centred bg_white z_1">
                    <div className="text">
                      <div className="icon-box p_relative fs_60 theme_color lh_60 mb_12">
                        <i className="icon-16"></i>
                      </div>
                      <h4 className="fs_20 lh_32 fw_medium">
                        Love The Nature
                      </h4>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_1">
                <div className="p_relative d_block ml_30">
                  <div className="sec-title mb_35">
                    <span className="sub-title">About</span>
                    <h2>
                      Oakwood Resort <br />
                    </h2>
                  </div>
                  <div className="text p_relative d_block mb_40 text-justify">
                    <p>
                      Oakwood Resorts is situated near the renowned Tadoba
                      Andhari Tiger Reserve Located in the Chandrapur district
                      of Maharashtra. This reserve is recognized as one of
                      India's top tiger reserves, with its tropical dry
                      deciduous forest supporting a population of over 80 tigers
                      and more than 200 tigers in the wider landscape. Tadoba is
                      also home to various carnivore species, including leopards
                      and dholes, with the sambar, chital, wild pig, and gaur
                      serving as common prey.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- about-style-two end --> */}

      {/* <!-- working-section --> */}
      <section className="working-section alternat-2 p_relative pb_30">
        <div className="auto-container">
          <div className="inner-container p_relative d_block bg_white b_radius_5">
            <div className="shape-layer">
              <div
                className="shape-1 p_absolute"
                style={{
                  backgroundImage:
                    "url(" + "assets/images/shape/shape-5.png" + ")",
                }}
              ></div>
              <div
                className="shape-2 p_absolute"
                style={{
                  backgroundImage:
                    "url(" + "assets/images/shape/shape-6.png" + ")",
                }}
              ></div>
            </div>
            <div className="row clearfix">
              <div className="col-lg-5 col-md-12 col-sm-12 image-column">
                <figure className="image-box p_relative d_block img_hover_3">
                  <img src="assets/images/resource/Tadoba-Tiger.webp" alt="The Oakwood Resort" />
                </figure>
              </div>
              <div className="col-lg-7 col-md-12 col-sm-12 content-column">
                <div className="content_block_2">
                  <div className="p_relative d_block pt_0 pl_0 pr_0 pb_0">
                    <div className="sec-title mb_10">
                      <span className="sub-title">History about</span>
                      <h2>Tadoba Jungle</h2>
                    </div>
                    <div className="text p_relative d_block mb_35">
                      <p className="aboutpage_ptag">
                        There is a story that is told in these parts: the
                        mythology of a man who became a deity. Taru, a Gond
                        tribal, a respected village headman, encountered a
                        mighty tiger at a lake near his village. A fierce battle
                        ensued, though its conclusion is disputed: some say that
                        Taru vanquished the tiger, while others believe that it
                        was Taru, despite his valiance, who was slain. At any
                        rate, the man passed into legend. A shrine was erected
                        in his honor and the lake, as well as the forests
                        surrounding it, came to bear his name. Hence, from Taru,
                        comes Tadoba.
                      </p>
                      <br />
                      <p className="aboutpage_ptag">
                        The Gond kings ruled over this area for several
                        centuries, as they did much of central India. The
                        Marathas established their rule in the 18th century,
                        followed by the British about a century later. Tadoba’s
                        forests and grasslands became part of the ‘Raj’ and
                        subject to its forest management practices. The
                        protection of timber stocks was the primary interest,
                        and wildlife conservation followed incidentally.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <p className="text-justify col-sm-12">
                The area was declared a Reserved Forest in 1879. The shooting of
                tigers, except with ‘special permits’ was stopped in 1905.
                Restrictions were placed on the shooting of all animals in 1931.
                A total of 45 sq km surrounding Tadoba Lake was proclaimed a
                sanctuary in 1935. In 1942 the area was declared a game reserve,
                with Moharli, Karwa, Kalsa, and Mul being the designated
                shooting blocks. Permits were issued to shoot tigers in the
                Karwa and Kalsa blocks.
              </p>

              <section className="about-section p_relative pt_20">
                <div className="auto-container">
                  <div className="row align-items-center clearfix">
                    <div className="col-lg-6 col-md-12 col-sm-12 content-column pl_0">
                      <div className="content_block_1">
                        <div className="p_relative d_block mr_0">
                          <div className="text p_relative d_block text-justify">
                            <p className="col-sm-12">
                              Tadoba became one of India’s earliest national
                              parks when it was so notified in 1955, the same
                              year as Kanha. Yet its forests, grasslands, and
                              wildlife continued to suffer due to
                              overexploitation, extensive cultivation, hunting,
                              and poaching. It was only in the 1970s that the
                              four hunting blocks were finally closed for
                              business, though illegal hunting continued. In
                              1986, 506.32 sq km of forest land adjoining the
                              national park was notified as the Andhari Wildlife
                              Sanctuary. The national park and the wildlife
                              sanctuary were finally merged in 1993 when
                              Tadoba-Andhari Tiger Reserve (TATR), spanning
                              622.87 sq km, was established.
                            </p>{" "}
                            <br />
                            <p className="col-sm-12">
                              The notification of the tiger reserve was, of
                              course, only a beginning. The TATR that you see
                              today, this internationally acclaimed biodiversity
                              haven, is the legacy of all the dedicated forest
                              officers, frontline forest staff, and members of
                              NGOs and fringe communities that have worked over
                              the decades to protect it. Sustained conservation
                              efforts have led to the revival of tigers and
                              other wildlife here. These have included the
                              creation of undisturbed areas through the
                              incentivized voluntary relocation of villages from
                              inside the core area, grassland development to
                              improve prey base populations, improvement of
                              water availability through water conservation
                              measures, intensive monitoring of tigers, and most
                              important, strong, sustained protection measures
                              to safeguard wildlife from all threats.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 inner-column pr_0">
                      <div className="inner-content centred p_relative">
                        <div className="pattern-layer">
                          <div className="pattern-1 p_absolute"></div>
                        </div>
                        <figure className="img_hover_3">

                        <img
                          src="assets/images/services/aboutus_23.jpg"
                          className="b_radius_10"
                          alt="The Oakwood Resort"
                          />
                          </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <p className="aboutpage_ptag col-sm-12">
                Today, Tadoba-Andhari Tiger Reserve is regarded as one of the
                world’s most preferred tiger destinations, a glittering jewel in
                the Project Tiger crown. We are proud that our tigers (over 80
                in the reserve and 200 in the larger landscape) are secure and
                able to multiply in the presence of humans. Thanks to the
                reserve’s functional connectivity with other protected areas in
                the central Indian tiger landscape, Tadoba’s tigers are now
                found restocking the gene pools of protected areas such as
                Navegaon-Nagzira, Umred-Karhandla and Tipeshwar, and forests as
                far afield as Kawal, Nagarjunsagar, and Indravati.
              </p>
              <p className="aboutpage_ptag col-sm-12">
                Tigers generate the most interest and occupy the top position in
                the food web but it is the forest in its entirety, all creatures
                great and small, that make this tiger reserve tick. Tadoba’s
                enchantment is in the southern tropical dry deciduous jungle
                with its myriad grasslands and waterbodies. It is in the groves
                of bamboo, a keystone plant in this landscape that supplements
                food availability for herbivores keeps invasive weeds at bay,
                and serves as both a safe harbor and ambush cover for different
                species. It is in the stunning variety of insect life and bird
                life: the azure dartles and the harvestmen, the lesser adjutant
                storks and the oriental magpie-robins, the Tickell’s blue
                flycatchers and the Indian silverbills. It is in the prey
                species, the wild pigs and sambar and chital, and in the
                predators, the jungle cats and wild dogs, the leopards and the
                mugger crocodiles, and the mighty tigers.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- team-section --> */}
      <section className="team-section p_relative sec-pad centred">
        <div className="auto-container">
          <div className="sec-title centred mb_45">
            <h2>When to visit Tadoba</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 team-block">
              <div
                className="team-block-one wow fadeInUp animated animated"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box p_relative d_block pl_30 pt_30 pr_30 pb_25 b_shadow_7 b_radius_5">
                  <figure className="image-box p_relative d_block b_radius_5">
                    <a>
                      <img src="assets/images/team/nature_3.jpg" alt="The Oakwood Resort" className="p_0" />
                    </a>
                  </figure>
                  <div className="lower-content p_relative d_block pt_30">
                    <h3 className="p_relative d_block fs_22 fw_medium mb_5">
                      <a className="d_iblock">February to April</a>
                    </h3>
                    <span className="designation p_relative d_block fs_15">
                      Tadoba is in full bloom in the Spring. Palas aka the flame
                      of the forest (Butea monosperma), mahua (Madhuca indica),
                      kumbhi, and ghogar–dikemali (Gardenia
                      latifolia) are among the trees flowering. The golden
                      yellow chandeliers of bahava (Cassia fistula) begin to
                      attract birds.
                    </span>
                  </div> <br />
                  <div className="btn-box">
                    <Button
                      href="https://live.ipms247.com/booking/book-rooms-theoakwoodresortstadoba"
                      className="theme-btn btn-one"
                    >
                      Book Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 team-block">
              <div
                className="team-block-one wow fadeInUp animated animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box p_relative d_block pl_30 pt_30 pr_30 pb_25 b_shadow_7 b_radius_5">
                  <figure className="image-box p_relative d_block b_radius_5">
                    <a>
                      <img src="assets/images/team/nature_2.jpg" alt="The Oakwood Resort" className="p_0"/>
                    </a>
                  </figure>
                  <div className="lower-content p_relative d_block pt_30">
                    <h3 className="p_relative d_block fs_22 fw_medium mb_5">
                      <a className="d_iblock">April to June</a>
                    </h3>
                    <span className="designation p_relative d_block fs_15">
                      The weather may be scorching hot but the deciduous forest
                      is shedding its leaves, improving visibility and
                      increasing the chances of wildlife sightings. A great time
                      to be in the jungle and watch animals come down to
                      waterholes for a much needed drink.
                    </span>
                  </div><br />
                  <div className="btn-box">
                    <Button
                      href="https://live.ipms247.com/booking/book-rooms-theoakwoodresortstadoba"
                      className="theme-btn btn-one"
                    >
                      Book Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 team-block">
              <div
                className="team-block-one wow fadeInUp animated animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box p_relative d_block pl_30 pt_30 pr_30 pb_25 b_shadow_7 b_radius_5">
                  <figure className="image-box p_relative d_block b_radius_5">
                    <a>
                      <img src="assets/images/team/nature_1.jpg" alt="The Oakwood Resort" className="p_0"/>
                    </a>
                  </figure>
                  <div className="lower-content p_relative d_block pt_30">
                    <h3 className="p_relative d_block fs_22 fw_medium mb_5">
                      <a className="d_iblock">July to October</a>
                    </h3>
                    <span className="designation p_relative d_block fs_15">
                      Tadoba is verdant in the monsoon. Some parts of the park
                      are open for tourism and the jungle is a sight to behold
                      in the pouring rain. There is a surplus of food and many
                      animals, from birds and butterflies to megafauna, produce
                      offspring in this season.
                    </span>
                  </div><br />
                  <div className="btn-box">
                    <Button
                      href="https://live.ipms247.com/booking/book-rooms-theoakwoodresortstadoba"
                      className="theme-btn btn-one"
                    >
                      Book Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 team-block pt_30">
              <div
                className="team-block-one wow fadeInUp animated animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box p_relative d_block pl_30 pt_30 pr_30 pb_25 b_shadow_7 b_radius_5">
                  <figure className="image-box p_relative d_block b_radius_5">
                    <a>
                      <img src="assets/images/team/nature_4.jpg" alt="The Oakwood Resort" className="p_0"/>
                    </a>
                  </figure>
                  <div className="lower-content p_relative d_block pt_30">
                    <h3 className="p_relative d_block fs_22 fw_medium mb_5">
                      <a className="d_iblock">November to January</a>
                    </h3>
                    <span className="designation p_relative d_block fs_15">
                      The air is crisp in the early mornings and late evenings
                      now. The forest is lovely in the warmth of the winter sun,
                      and the animals too enjoy basking in the golden light. The
                      mating calls of male deer resound in the forests. Its a
                      great time for birdwatching too, with the arrival of
                      migratory birds.
                    </span>
                    
                  </div> <br />
                  <div className="btn-box">
                    <Button
                      href="https://live.ipms247.com/booking/book-rooms-theoakwoodresortstadoba"
                      className="theme-btn btn-one"
                    >
                      Book Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- team-section end --> */}
    </div>
  );
};
