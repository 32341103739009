import "./App.css";
import { AllRoutes } from "./Routes/AllRoutes";
import { Header } from "./Components/Header";
import { Footer } from "./Components/Footer";
import Check from "./Components/Header";
import { BrowserRouter} from "react-router-dom";
import { WhatsApp } from "./Components/WhatsApp";


function App() {
  return (
    <div>
      <BrowserRouter>
      <Check></Check>
      <AllRoutes></AllRoutes>
      <Footer></Footer> 
      <WhatsApp></WhatsApp>
      </BrowserRouter>
    </div>
  );
}

export default App;
