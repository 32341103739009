import React from "react";
import "./header.css";
import { Component } from "react";
import Button from "react-bootstrap/Button";
import { NavLink } from "react-router-dom";

class Header extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    return (
      <div>
        <div className="header-top">
          <div
            className="shape"
            style={{
              backgroundImage:
                "url(" + "assets/images/shape/shape-16.png" + ")",
            }}
          ></div>
          <div className="auto-container">
            <div className="top-inner clearfix">
              <div className="left-column pull-left">
                <ul className="social-links clearfix">
                  <li>
                    <p>Follow Us:</p>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/theoakwoodresorts/" target='_blank'>
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/theoakwoodresorts/" target='_blank'>
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/theoakwoodresorts/" target='_blank'>
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/UCqd7tXLROs9VFIzXXUFWvdg/" target='_blank'>
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="right-column pull-right">
                <ul className="info-box clearfix">
                  <li>
                    <div className="icon-box">
                      <i className="fas fa-phone"></i>
                    </div>
                    <p>
                      Call: <a href="tel:+91 8080898133">+91 8080898133</a>
                    </p>
                  </li>
                  <li>
                    <div className="icon-box">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <p>
                      Email:{" "}
                      <a href="tel:theoakwoodresorts@gamil.com">
                        theoakwoodresorts@gmail.com
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="navBarMain">
            <div>
              <div className="logo-box">
                <figure className="logo">
                  <a href="/">
                    <img
                      src="assets/images/the-oak-wood-website-logo.png"
                      alt="The Oakwood Resort"
                      className="logoimage"
                    />
                  </a>
                </figure>
              </div>
            </div>
            <div>
              <div>
                <ul
                  id="navbarNew"
                  className={
                    this.state.clicked ? "#navbarNew active" : "#navbarNew"
                  }
                >
                  <li>
                    <NavLink reloadDocument to="/">Home</NavLink>
                  </li>
                  <li>
                    <NavLink reloadDocument to="/aboutus">About Us</NavLink>
                  </li>
                  <li>
                    <NavLink reloadDocument to="/services">Services</NavLink>
                  </li>
                  <li>
                    <NavLink reloadDocument to="/room-booking">Room</NavLink>
                  </li>
                  <li>
                    <NavLink reloadDocument to="/corporate-affairs">Corporate Affairs</NavLink>
                  </li>
                  <li>
                    <NavLink reloadDocument to="/safari">Safari</NavLink>
                  </li>
                  <li>
                    <NavLink reloadDocument to="/gallery">Gallery</NavLink>
                  </li>
                  <li>
                      <NavLink reloadDocument to="/blogs">Blogs</NavLink>
                  </li>
                  <li>
                    <NavLink reloadDocument to="/contactus">Contact us</NavLink>
                  </li>
                </ul>
              </div>
              <div id="mobile" onClick={this.handleClick}>
                <i
                  id="bar"
                  className={
                    this.state.clicked ? "fas fa-times" : "fas fa-bars"
                  }
                ></i>
              </div>
            </div>
            <div>
              <div className="nav-right">
                <div className="btn-box">
                  <Button href="https://live.ipms247.com/booking/book-rooms-theoakwoodresortstadoba" className="theme-btn12 btn-one btn--glow" target="_blank">
                    Book Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <header className="main-header">
          <div className="sticky-header">
            <div className="navBarMain">
              <div>
                <div className="logo-box">
                  <figure className="logo">
                    <a href="/">
                      <img
                        src="assets/images/the-oak-wood-website-logo.png"
                        alt="The Oakwood Resort"
                        className="logoimage"
                        
                      />
                    </a>
                  </figure>
                </div>
              </div>
              <div>
                <div>
                  <ul
                    id="navbarNew"
                    className={
                      this.state.clicked ? "#navbarNew active" : "#navbarNew"
                    }
                  >
                    <li>
                      <NavLink reloadDocument to="/">Home</NavLink>
                    </li>
                    <li>
                      <NavLink reloadDocument to="/aboutus">About Us</NavLink>
                    </li>
                    <li>
                      <NavLink reloadDocument to="/services">Services</NavLink>
                    </li>
                    <li>
                      <NavLink reloadDocument to="/room-booking">Room</NavLink>
                    </li>
                    <li>
                      <NavLink reloadDocument to="/corporate-affairs">Corporate Affairs</NavLink>
                    </li>
                    <li>
                      <NavLink reloadDocument to="/safari">Safari</NavLink>
                    </li>
                    <li>
                      <NavLink reloadDocument to="/gallery">Gallery</NavLink>
                    </li>
                    <li>
                      <NavLink reloadDocument to="/blogs">Blogs</NavLink>
                    </li>
                    <li>
                      <NavLink reloadDocument to="/contactus">Contact us</NavLink>
                    </li>
                  </ul>
                </div>
                <div id="mobile" onClick={this.handleClick}>
                  <i
                    id="bar"
                    className={
                      this.state.clicked ? "fas fa-times" : "fas fa-bars"
                    }
                  ></i>
                </div>
              </div>
              <div>
                <div className="nav-right">
                  <div className="btn-box">
                    <Button href="https://live.ipms247.com/booking/book-rooms-theoakwoodresortstadoba" className="theme-btn12 btn-one btn--glow" target="_blank">
                      Book Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div>
          <div class="element1 d-lg-none">
            <a class="buttonLink" href="https://live.ipms247.com/booking/book-rooms-theoakwoodresortstadoba">
              <button class="verticalButton">Book Now</button>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default Header;
