import { Helmet } from "react-helmet";

export const Services = () => {
  return (
    <div>
      <Helmet>
        <title>Resorts in Tadoba Navegaon | Tadoba Jungle Safari and Resorts </title>
        <meta name="description" content="The Oakwood Resorts offers luxurious accommodations with stunning pool views, ensuring a serene and comfortable stay for all guests. Best resort in Tadoba, nestled in the heart of the tadoba forest, the majestic realme of the tiger." />
        <meta name="keyword" content="The Oakwood Resorts,Best resorts in tadoba,Resorts near Tadoba Andhari National Park,Resorts near tadoba ,Hotels in tadoba,Jungle safari and resorts tadoba,Tadoba jungle safari and resorts ,Luxury resorts in tadoba,Resorts in tadoba Navegaon ,Resorts and Jungle safari in tadoba,Best Tadoba Forest Resort"/>
      </Helmet>
      {/* <!-- Page Title --> */}
      <section
        className="page-title p_relative pt_150 pb_180 centred page-title-height"
        style={{
          backgroundImage:
            "url(" + "assets/images/resource/pench-weekend-package.jpg" + ")",
        }}
      >
        <div
          className="pattern-layer p_absolute"
          style={{
            backgroundImage: "url(" + "assets/images/shape/shape-2.png" + ")",
          }}
        ></div>
        <div className="auto-container">
          <div className="content-box">
            <h1 className="d_block fs_55 lh_70 mb_20 color_white">Services</h1>
            <p className="d_block fs_20 lh_30 color_white">
              {/* Cupidatat non proident */}
            </p>
          </div>
        </div>
      </section>
      {/* <!-- End Page Title --> */}

      <div>
        <section className="services pt_20 pb_20 pdtf">
          <div className="container">
            <div className="row">
              <div
                className="col-md-6 p_0 animate-box fadeInLeft animated"
                data-animate-effect="fadeInLeft"
              >
                <div className="img left">
                  <a>
                    <img src="assets/images/services/road.jpg" alt="The Oakwood Resort" />
                  </a>
                </div>
              </div>
              <div
                className="col-md-6 p_0 bg-cream valign animate-box fadeInRight animated"
                data-animate-effect="fadeInRight"
              >
                <div className="content">
                  <div className="cont text-left pt_30 pdtf2">
                    <div className="info">
                      <h4>By Road</h4>
                    </div>
                    <p className="text-justify">
                      Nagpur is the closest large city and is just about 100km
                      from the Kolara Gate and 150km from the Moharli Gate. Good
                      highways connect Tadoba to Nagpur, Hyderabad, Amravati,
                      and Yevatmal. Tourists even drive in from as far afield as
                      Pune, Bangalore, Kolkata, and Mumbai!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-6 bg-cream p_0 order2 valign animate-box fadeInLeft animated"
                data-animate-effect="fadeInLeft"
              >
                <div className="content">
                  <div className="cont text-left pt_30 pdtf2">
                    <div className="info">
                      <h4>By Air</h4>
                    </div>

                    <p className="text-justify">
                      Nagpur’s Dr. Babasaheb Ambedkar International Airport,
                      which has good connections to major Indian cities, is the
                      nearest airport.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 p_0 order1 animate-box fadeInRight animated"
                data-animate-effect="fadeInRight"
              >
                <div className="img">
                  <a>
                    <img src="assets/images/services/plane.jpg" alt="The Oakwood Resort" />
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-6 p_0 animate-box fadeInLeft animated"
                data-animate-effect="fadeInLeft"
              >
                <div className="img left">
                  <a>
                    <img src="assets/images/services/train.jpg" alt="The Oakwood Resort" />
                  </a>
                </div>
              </div>
              <div
                className="col-md-6 p_0 bg-cream valign animate-box fadeInRight animated"
                data-animate-effect="fadeInRight"
              >
                <div className="content">
                  <div className="cont text-left pt_30 pdtf2">
                    <div className="info">
                      <h4>By Train</h4>
                    </div>

                    <p className="text-justify">
                      The nearby Railway Stations are Chandrapur, Wardha, and
                      Nagpur. From Chandrapur, buses and taxis connect to
                      Warora, after which the district road connects to Chimur
                      via Anandvan, Shegaon and Kharsinghi..
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
