import React from 'react'
import { Helmet } from "react-helmet";

export const RoomsBooking = () => {
  return (
    <div>
      <Helmet>
        <title>Hotels in Tadoba | Best Tadoba Forest Resort | Wildlife Resorts </title>
        <meta name="description" content="At The Oakwood Resorts, Choose from a variety of elegantly furnished rooms and suites, each designed to provide ultimate comfort and breathtaking views. Book jungle safari and resorts in Tadoba with us." />
        <meta name="keyword" content="The Oakwood Resorts,Best resorts in tadoba,Resorts near Tadoba Andhari National Park,Resorts near tadoba ,Hotels in tadoba,Jungle safari and resorts tadoba,Tadoba jungle safari and resorts ,Luxury resorts in tadoba,Resorts in tadoba Navegaon ,Resorts and Jungle safari in tadoba,Best Tadoba Forest Resort"/>
      </Helmet>
        {/* <!-- Page Title --> */}
      <section
        className="page-title p_relative pt_150 pb_180 centred page-title-height"
        style={{
          backgroundImage:
            "url(" + "assets/images/resource/pench-weekend-package.jpg" + ")",
        }}
      >
        <div
          className="pattern-layer p_absolute"
          style={{
            backgroundImage: "url(" + "assets/images/shape/shape-2.png" + ")",
          }}
        ></div>
        <div className="auto-container">
          <div className="content-box">
            <h1 className="d_block fs_55 lh_70 mb_20 color_white">Room Booking</h1>
            <p className="d_block fs_20 lh_30 color_white">
              {/* Cupidatat non proident */}
            </p>
          </div>
        </div>
      </section>
          {/* <!-- End Page Title --> */}


          <section className="service-section alternat-2 p_relative centred pt_100 px_160">
        <div className="auto-container">
          <div className="row clearfix">
            <div className=" col-md-6 service-block">
              <div
                className="service-block-one wow fadeInUp animated animated"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animatioDuration: "1500ms",
                  animationDelay: "0ms",
                }}
              >
                <div className="inner-box p_relative d_block bg_white b_radius_5 b_shadow_6 tran_5 mb_30">
                  <div className="image-box p_relative d_block mb_45">
                    <figure className="image p_relative d_block img_hover_1">
                      <img
                        src="assets/images/gallery/gallery2.jpg"
                        alt="The Oakwood Resort"
                      />
                    </figure>
                  </div>
                  <div className="lower-content p_relative d_block pl_20 pr_20 pb_40">
                    <h3 className="p_relative d_block fs_22 fw_medium mb_17">
                      <a
                        href="https://live.ipms247.com/booking/book-rooms-theoakwoodresortstadoba"
                        className="d_iblock"
                      >
                        Executive Cottage EP
                      </a>
                    </h3>
                    {/* <p className="mb_17 lh_26">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit ad officiis ipsa voluptatibus ipsam at porro.
                    </p> */}
                    <div className="btn-box p_relative d_block">
                      <a href="https://live.ipms247.com/booking/book-rooms-theoakwoodresortstadoba" className="theme-btn btn-one">
                        Book Now
                      </a>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-md-6 service-block">
              <div
                className="service-block-one wow fadeInUp animated animated"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animatioDuration: "1500ms",
                  animationDelay: "0ms",
                }}
              >
                <div className="inner-box p_relative d_block bg_white b_radius_5 b_shadow_6 tran_5 mb_30">
                  <div className="image-box p_relative d_block mb_45">
                    <figure className="image p_relative d_block img_hover_1">
                      <img
                        src="assets/images/gallery/gallery2.jpg"
                        alt="The Oakwood Resort"
                      />
                    </figure>
                  </div>
                  <div className="lower-content p_relative d_block pl_20 pr_20 pb_40">
                    <h3 className="p_relative d_block fs_22 fw_medium mb_17">
                      <a
                        href="https://live.ipms247.com/booking/book-rooms-theoakwoodresortstadoba"
                        className="d_iblock"
                      >
                        Premium cottage EP
                      </a>
                    </h3>
                    {/* <p className="mb_17 lh_26">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit ad officiis ipsa voluptatibus ipsam at porro.
                    </p> */}
                    <div className="btn-box p_relative d_block">
                      <a href="https://live.ipms247.com/booking/book-rooms-theoakwoodresortstadoba" className="theme-btn btn-one">
                        Book Now
                      </a>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 service-block">
              <div
                className="service-block-one wow fadeInUp animated animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animatioDuration: "1500ms",
                  animationDelay: "0ms",
                }}
              >
                <div className="inner-box p_relative d_block bg_white b_radius_5 b_shadow_6 tran_5 mb_30">
                  <div className="image-box p_relative d_block mb_45">
                    <figure className="image p_relative d_block img_hover_1">
                      <img src="assets/images/gallery/gallery2.jpg" alt="The Oakwood Resort" />
                    </figure>
                  </div>
                  <div className="lower-content p_relative d_block pl_20 pr_20 pb_40">
                    <h3 className="p_relative d_block fs_22 fw_medium mb_17">
                      <a
                        href="https://live.ipms247.com/booking/book-rooms-theoakwoodresortstadoba"
                        className="d_iblock"
                      >
                        Suite EP
                      </a>
                    </h3>
                    {/* <p className="mb_17 lh_26">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit ad officiis ipsa voluptatibus ipsam at porro.
                    </p> */}
                    <div className="btn-box p_relative d_block">
                      <a href="https://live.ipms247.com/booking/book-rooms-theoakwoodresortstadoba" className="theme-btn btn-one">
                        Book Now
                      </a>
                  </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" col-md-6 service-block">
              <div
                className="service-block-one wow fadeInUp animated animated"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animatioDuration: "1500ms",
                  animationDelay: "0ms",
                }}
              >
                <div className="inner-box p_relative d_block bg_white b_radius_5 b_shadow_6 tran_5 mb_30">
                  <div className="image-box p_relative d_block mb_45">
                    <figure className="image p_relative d_block img_hover_1">
                      <img
                        src="assets/images/gallery/gallery2.jpg"
                        alt="The Oakwood Resort"
                      />
                    </figure>
                  </div>
                  <div className="lower-content p_relative d_block pl_20 pr_20 pb_40">
                    <h3 className="p_relative d_block fs_22 fw_medium mb_17">
                      <a
                        href="https://live.ipms247.com/booking/book-rooms-theoakwoodresortstadoba"
                        className="d_iblock"
                      >
                        Supreme Cottage EP
                      </a>
                    </h3>
                    {/* <p className="mb_17 lh_26">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit ad officiis ipsa voluptatibus ipsam at porro.
                    </p> */}
                    <div className="btn-box p_relative d_block">
                      <a href="https://live.ipms247.com/booking/book-rooms-theoakwoodresortstadoba" className="theme-btn btn-one">
                        Book Now
                      </a>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}
