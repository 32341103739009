import React,{useState} from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";



export const BookingForm = () => {

  const captchaSumbit = () =>{

  }

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    country: '',
    sdate: '',
    edate: '',
    no_per: '',
    message: ''
  });
//   const [successMessage, setSuccessMessage] = useState('');
const [alertType, setAlertType] = useState(null);
const [alertMessage, setAlertMessage] = useState(null);
  

const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Send form data to PHP backend
    axios
      .post('https://theoakwoodresorts.com/oakwoodapi/booking-now.php', formData)
      .then((response) => {
        // Handle response from PHP backend
        console.log(response.data);
        // setSuccessMessage(response.data.message);
        if (response.data.success) {
            setAlertType('success');
            setAlertMessage('Form submitted successfully');
          } else {
            setAlertType('danger');
            setAlertMessage('Form submission failed');
          }
        
        // Reset form fields
        setFormData({ name: '', email: '', phone:'', country:'', sdate:'', edate:'', no_per:'', message: '' });
      })
      .catch((error) => {
        console.error('Error:', error);
        setAlertType('danger');
        setAlertMessage('An error occurred');
      });
  };

  return (
    <div>
      <section className="page-title p_relative pt_150 pb_180 centred page-title-height" style={{backgroundImage: "url("+"assets/images/resource/pench-weekend-package.jpg"+")"}}>
            <div className="pattern-layer p_absolute" style={{backgroundImage: "url("+"assets/images/shape/shape-2.png"+")"}}></div>
            <div className="auto-container">
                <div className="content-box">
                    <h1 className="d_block fs_55 lh_70 mb_20 color_white">Booking Enquiry</h1>
                    <p className="d_block fs_20 lh_30 color_white">
                        {/* Cupidatat non proident */}
                        </p>
                </div>
            </div>
        </section>
      <section className="gallery-section pt_50 pb_50">
        <div className="auto-container all_padding_200">
          <div class="sec-title mb_35"><h2>Booking Enquiry Form</h2></div>
          {alertMessage && (
                <div className={`alert alert-${alertType}`} role="alert">
                {alertMessage}
                </div>
            )}
          <form method="post" id="contact-form"
            className="default-form" onSubmit={handleSubmit}>
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-12 form-group mb_30">
              <label>Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  required
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 form-group mb_30">
              <label>Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  required
                  aria-required="true"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 form-group mb_30">
              <label>Phone</label>
                <input
                  type="text"
                  name="phone"
                  required
                  placeholder="Phone Number"
                  aria-required="true"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 form-group mb_30">
              <label>Country</label>
                <input
                  type="text"
                  name="country"
                  required
                  placeholder="Country"
                  aria-required="true"
                  value={formData.country}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 form-group mb_30">
                <label>Arrival Date</label>
                <input
                  type="date"
                  name="sdate"
                  required
                  placeholder="Arrival Date"
                  aria-required="true"
                  value={formData.sdate}
                  onChange={handleChange}
                />
              </div>
              
              <div className="col-lg-4 col-md-12 col-sm-12 form-group mb_30">
              <label>Departure Date</label>
                <input
                  type="date"
                  name="edate"
                  required
                  placeholder="Departure Date"
                  aria-required="true"
                  value={formData.edate}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 form-group mb_30">
              <label>No. of Person</label>
                <input
                  type="number"
                  name="no_per"
                  required
                  placeholder="No. of Person"
                  aria-required="true"
                  value={formData.no_per}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 form-group mb_30">
              <label>Comments</label>
                <textarea name="message" placeholder="Comment" required value={formData.message}
                  onChange={handleChange}></textarea>
              </div>
              {/* <div className="col-lg-6 col-md-12 col-sm-12 form-group mb_30">
                <ReCAPTCHA sitekey="6Lfk5CMoAAAAAOcs5UX4gXf2QYvpREaeepT-tZ6y" onChange={captchaSumbit}/>
              </div> */}
              <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn mr-0">
                <button
                  className="theme-btn btn-one"
                  type="submit"
                  name="submit-form"
                >
                  Submit Now
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};
