import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import { AboutUs } from "../Pages/AboutUs";
import { Services } from "../Pages/Services";
import { Safari } from "../Pages/Safari";
import { Gallery } from "../Pages/Gallery";
import { ContactUs } from "../Pages/ContactUs";
import { PageNotFound } from "../Pages/PageNotFound";
import { BookingForm } from "../Components/BookingForm";
import { CorporateAffairs } from "../Pages/CorporateAffairs";
import { RoomsBooking } from "../Pages/RoomsBooking";
import { PrivacyPolicy } from "../Policy_pages/PrivacyPolicy";
import { TermsOfService } from "../Policy_pages/TermsOfService";
import { ReturnRefund } from "../Policy_pages/ReturnRefund";
import { BudgetFriendly } from "../AllBlogs/BudgetFriendly";
import { AllBlogs } from "../Pages/AllBlogs";
import { BestJungleSafari } from "../AllBlogs/BestJungleSafari";
import { WhatToExpectResort } from "../AllBlogs/WhatToExpectResort";
import { WhyChooseTheOakwoodResortsinTadoba } from "../AllBlogs/WhyChooseTheOakwoodResortsinTadoba";
import { TheBestTimeToVisitTadoba } from "../AllBlogs/TheBestTimeToVisitTadoba";
import { TopFiveJunglesToVisitDuringTheRainySeason } from "../AllBlogs/TopFiveJunglesToVisitDuringTheRainySeason";


export const AllRoutes = () => {
  return (
    <div>
        <Routes>
          <Route index element={<Home></Home>} />
          <Route path="aboutus" element={<AboutUs></AboutUs>} />
          <Route path="services" element={<Services></Services>} />
          <Route path="safari" element={<Safari></Safari>} />
          <Route path="gallery" element={<Gallery></Gallery>} />
          <Route path="contactus" element={<ContactUs></ContactUs>} />
          <Route path="booknow" element={<BookingForm></BookingForm>} />
          <Route path="corporate-affairs" element={<CorporateAffairs></CorporateAffairs>} />
          <Route path="room-booking" element={<RoomsBooking></RoomsBooking>} />
          <Route path="refund-return-policy" element={<ReturnRefund></ReturnRefund>} />
          <Route path="term-conditions" element={<TermsOfService></TermsOfService>} />
          <Route path="privacy-policy" element={<PrivacyPolicy></PrivacyPolicy>} />
          <Route path="*" element={<PageNotFound></PageNotFound>} />


          <Route path="blogs" element={<AllBlogs/>} />
          <Route path="the-oakwood-resorts-budget-friendly-resorts-in-tadoba" element={<BudgetFriendly/>} />
          <Route path="the-oakwood-resorts–best-for-jungle-safari-and-resort-experience" element={<BestJungleSafari/>} />
          <Route path="what-to-expect-from-the-oakwood-resorts-in-tadoba" element={<WhatToExpectResort/>} />
          <Route path="why-choose-the-oakwood-resorts-in-tadoba" element={<WhyChooseTheOakwoodResortsinTadoba/>} />
          <Route path="the-best-time-to-visit-tadoba-jungle-july-rainy-season" element={<TheBestTimeToVisitTadoba/>} />
          <Route path="top-5-jungles-to-visit-during-the-rainy-season" element={<TopFiveJunglesToVisitDuringTheRainySeason/>} />


        </Routes>
    </div>
  );
};
