import React from 'react'

export const Footer = () => {
  return (
    <div className="boxed_wrapper">
        {/* <!-- main-footer --> */}
        <footer className="main-footer p_relative">
            {/* <div className="footer-logo p_relative d_block pb_30 centred">
                <figure className="logo p_relative d_iblock">
                    <div className="shape p_absolute" style={{backgroundImage: "url("+"assets/images/shape/shape-13.png"+")"}}></div>
                    <a href="/"><img src="assets/images/the-oak-wood-website-logo.png" alt="The Oakwood Resort"/></a>
                </figure>
            </div> */}
            <div className="widget-section p_relative pt_50 pb_30">
                <div className="pattern-layer">
                    <div className="pattern-1 p_absolute" style={{backgroundImage: "url("+"assets/images/shape/shape-14.png"+")"}}></div>
                    <div className="pattern-2 p_absolute" style={{backgroundImage: "url("+"assets/images/shape/shape-15.png"+")"}}></div>
                </div>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                            <div className="footer-widget about-widget">
                                <div className="widget-title p_relative d_block mb_25">
                                    <h3 className="fs_26 lh_35 color_black">About</h3>
                                </div>
                                <div className="widget-content">
                                    <div className="text p_relative d_block mb_30 text-justify">
                                        <p>Oakwood Resorts is situated near the renowned Tadoba Andhari Tiger Reserve Located in the Chandrapur district of Maharashtra. This reserve is recognized as one of India's top tiger reserves, with its tropical dry deciduous forest supporting a population of over 80 tigers and more than 200 tigers in the wider landscape.</p>
                                    </div>
                                    <ul className="social-links clearfix">
                                        <li className="p_relative d_iblock float_left mr_10"><a href="https://www.facebook.com/theoakwoodresorts/" className="d_iblock color_black hov_bg b_radius_5" target='_blank'><i className="fab fa-facebook-f"></i></a></li>
                                        <li className="p_relative d_iblock float_left mr_10"><a href="https://www.instagram.com/theoakwoodresorts/" className="d_iblock color_black hov_bg b_radius_5" target='_blank'><i className="fab fa-instagram"></i></a></li>
                                        <li className="p_relative d_iblock float_left mr_10"><a href="https://www.youtube.com/channel/UCqd7tXLROs9VFIzXXUFWvdg/" className="d_iblock color_black hov_bg b_radius_5" target='_blank'><i className="fab fa-youtube"></i></a></li>
                                        <li className="p_relative d_iblock float_left"><a href="https://www.linkedin.com/company/theoakwoodresorts/" className="d_iblock color_black hov_bg b_radius_5" target='_blank'><i className="fab fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                            <div className="footer-widget links-widget ml_70">
                                <div className="widget-title p_relative d_block mb_25">
                                    <h3 className="fs_26 lh_35 color_black">Links</h3>
                                </div>
                                <div className="widget-content">
                                    <ul className="links-list clearfix">
                                        <li className="p_relative d_block mb_7"><a href="/aboutus" className="p_relative fs_15 d_iblock">About Us</a></li>
                                        <li className="p_relative d_block mb_7"><a href="/services" className="p_relative fs_15 d_iblock">Services</a></li>
                                        <li className="p_relative d_block mb_7"><a href="/room-booking" className="p_relative fs_15 d_iblock">Rooms</a></li>
                                        <li className="p_relative d_block mb_7"><a href="/corporate-affairs" className="p_relative fs_15 d_iblock">Corporate Affairs</a></li>
                                        <li className="p_relative d_block mb_7"><a href="/safari" className="p_relative fs_15 d_iblock">Safari</a></li>
                                        <li className="p_relative d_block mb_7"><a href="/gallery" className="p_relative fs_15 d_iblock">Gallery</a></li>
                                        <li className="p_relative d_block mb_7"><a href="/contactus" className="p_relative fs_15 d_iblock">Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12 footer-column">
                            <div className="footer-widget links-widget">
                                <div className="widget-title p_relative d_block mb_25">
                                    <h3 className="fs_26 lh_35 color_black">Bookings</h3>
                                </div>
                                <div className="widget-content">
                                    <ul className="links-list clearfix">
                                        <li className="p_relative d_block mb_7"><a href="/booknow" className="p_relative fs_15 d_iblock">Regular Safari</a></li>
                                        <li className="p_relative d_block mb_7"><a href="/booknow" className="p_relative fs_15 d_iblock">Tatkal Safari</a></li>
                                        <li className="p_relative d_block mb_7"><a href="/booknow" className="p_relative fs_15 d_iblock">Canter Safari</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                            <div className="footer-widget contact-widget">
                                <div className="widget-title p_relative d_block mb_25">
                                    <h3 className="fs_26 lh_35 color_black">Contacts</h3>
                                </div>
                                <div className="widget-content">
                                    <ul className="contact-info clearfix">
                                        <li className="p_relative d_block mb_20">
                                            <p className="fs_15">Navegaon Gate, Tadoba Andhari Tiger Reserve, Khadsangi, Maharashtra 442906</p>
                                        </li>
                                        <li className="p_relative d_block mb_20">
                                            <p className="fs_15"><a href="tel:+91 8080898133">+91 8080898133</a></p>
                                            {/* <p className="fs_15"><a href="tel:+91 9373285702">+91 9373285702</a></p> */}
                                        </li>
                                        <li className="p_relative d_block">
                                            <p className="fs_15"><a href="mailto:theoakwoodresorts@gmail.com">theoakwoodresorts@gmail.com</a></p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                <div>
                    <div class="d-flex mt-4">
                         <div class="col-lg-12 text-justify">
                            <a href="/" class="footer-color-txt-keyword; ">The Oakwood Resorts</a> | &nbsp;
                            <a href="/" class="footer-color-txt-keyword; ">Best resorts in tadoba</a> | &nbsp;
                            <a href="aboutus" class="footer-color-txt-keyword; ">Resorts near Tadoba Andhari National Park</a> | &nbsp;
                            <a href="services" class="footer-color-txt-keyword;">Resorts near tadoba </a> | &nbsp;
                            <a href="/" class="footer-color-txt-keyword;">Hotels in tadoba</a> | &nbsp;
                            <a href="safari" class="footer-color-txt-keyword;">Jungle safari and resorts tadoba</a> | &nbsp;
                            <a href="safari" class="footer-color-txt-keyword;">Tadoba jungle safari and resorts </a> | &nbsp;
                            <a href="room-booking" class="footer-color-txt-keyword;">Luxury resorts in tadoba</a> | &nbsp;
                            <a href="gallery" class="footer-color-txt-keyword;">Resorts in tadoba Navegaon</a> | &nbsp;
                            <a href="corporate-affairs" class="footer-color-txt-keyword;">Resorts and Jungle safari in tadoba</a> | &nbsp;
                            <a href="safari" class="footer-color-txt-keyword;">Tiger safari in Tadoba </a> | &nbsp;
                            <a href="safari" class="footer-color-txt-keyword;">Wildlife Safari</a> | &nbsp;
                            <a href="safari" class="footer-color-txt-keyword;">Wildlife adventure</a> | &nbsp;
                            
                        </div>
                     </div>
                </div>
            </div>
        </div>
            <div className="footer-bottom py_10">
                <div className="auto-container">
                    <div className="bottom-inner clearfix">
                        <div className="copyright pull-left">
                            <p className="fs_15"><a href="/">The Oakwood Resort</a> &copy; 2023 All Right Reserved.</p>
                        </div>
                        <ul className="footer-nav pull-right clearfix">
                            <li className="float_left mr_20 p_relative d_iblock fs_15"><a href="refund-return-policy">Return & Refund Policy</a></li>
                            <li className="float_left mr_30 p_relative d_iblock fs_15"><a href="term-conditions">Terms & Conditions</a></li>
                            <li className="float_left p_relative d_iblock fs_15"><a href="privacy-policy">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        {/* <!-- main-footer end --> */}
    </div>
  )
}
