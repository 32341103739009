

export const PageNotFound = () => {
  return (
    <div>
      <section
        className="page-title p_relative pt_150 pb_180 centred page-title-height"
        style={{
          backgroundImage:
            "url(" + "assets/images/resource/pench-weekend-package.jpg" + ")",
        }}
      >
        <div
          className="pattern-layer p_absolute"
          style={{
            backgroundImage: "url(" + "assets/images/shape/shape-2.png" + ")",
          }}
        ></div>
        <div className="auto-container">
          <div className="content-box">
            <h1 className="d_block fs_55 lh_70 mb_20 color_white">404</h1>
            <p className="d_block fs_20 lh_30 color_white">
              {/* Cupidatat non proident */}
            </p>
          </div>
        </div>
      </section>


        <section class="error-page-section centred p_relative pt_140 pb_150">
            <div class="auto-container">
                <div class="inner-box p_relative d_block">
                    <h1 class="fw_bold mb_20 p_relative d_block">404</h1>
                    <h2 class="p_relative d_block fs_30 lh_40 mb_40 fw_sbold">We're not being able to find the page <br/>you're looking for</h2>
                    <a href="/" class="theme-btn btn-one">Back Home</a>
                </div>
            </div>
        </section>
    </div>
  )
}
