import React from 'react'

export const AllBlogs = () => {
  return (
    <section class="news-section p_relative sec-pad">
    <div class="auto-container">
        <div class="row clearfix">

         {/* Blog 6 */}
         <div class="col-lg-4 col-md-6 col-sm-12 news-block mt-4">
                        <div class="news-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <div class="inner-box p_relative d_block b_radius_5 b_shadow_7 bg_white">
                                <figure class="p_relative d_block"><a href="top-5-jungles-to-visit-during-the-rainy-season"><img src="assets/images/blogs/top-5-jungles-to-visit-during-the-rainy-season.jpg" alt="blogs-image"/></a></figure>
                                <div class="lower-content p_relative d_block pl_40 pt_35 pr_30 pb_40">
                                    <span class="post-date p_relative d_block fs_15 mb_5">July 17, 2024</span>
                                    <h3 class="p_relative d_block fs_20 lh_28 mb_5 fw_medium"><a href="top-5-jungles-to-visit-during-the-rainy-season" class="d_iblock hov_color">Top 5 Jungles to Visit This Year during the Rainy Season in Tadoba National Park</a></h3>
                                    
                                    <p class="fs_15 lh_26 mb_15">Visiting a jungle during the rainy season can be a thrilling...</p>
                                    <div class="btn-box text-center">
                                        <a href="top-5-jungles-to-visit-during-the-rainy-season" class="theme-btn btn-one">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
        </div>

         {/* Blog 5  */}
         <div class="col-lg-4 col-md-6 col-sm-12 news-block mt-4">
                        <div class="news-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <div class="inner-box p_relative d_block b_radius_5 b_shadow_7 bg_white">
                                <figure class="p_relative d_block"><a href="the-best-time-to-visit-tadoba-jungle-july-rainy-season"><img src="assets/images/blogs/the-best-time-to-visit-tadoba-jungle-july-rainy-season-1.jpg" alt="blogs-image"/></a></figure>
                                <div class="lower-content p_relative d_block pl_40 pt_35 pr_30 pb_40">
                                    <span class="post-date p_relative d_block fs_15 mb_5">July 11, 2024</span>
                                    <h3 class="p_relative d_block fs_20 lh_28 mb_5 fw_medium"><a href="the-best-time-to-visit-tadoba-jungle-july-rainy-season" class="d_iblock hov_color">The Best Time to Visit Tadoba Jungle Exploring the Buffer Zone during the July Rainy Season</a></h3>
                                    
                                    <p class="fs_15 lh_26 mb_15">When planning a wildlife adventure...</p>
                                    <div class="btn-box text-center">
                                        <a href="the-best-time-to-visit-tadoba-jungle-july-rainy-season" class="theme-btn btn-one">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
        </div>
        
        {/* Blog 4  */}
        <div class="col-lg-4 col-md-6 col-sm-12 news-block mt-4">
                        <div class="news-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <div class="inner-box p_relative d_block b_radius_5 b_shadow_7 bg_white">
                                <figure class="p_relative d_block"><a href="why-choose-the-oakwood-resorts-in-tadoba"><img src="assets/images/blogs/why-choose-the-oakwood-resorts-for-wildlife-safari-in-tadoba.jpeg" alt="blogs-image"/></a></figure>
                                <div class="lower-content p_relative d_block pl_40 pt_35 pr_30 pb_40">
                                    <span class="post-date p_relative d_block fs_15 mb_5">July 06, 2024</span>
                                    <h3 class="p_relative d_block fs_20 lh_28 mb_5 fw_medium"><a href="why-choose-the-oakwood-resorts-in-tadoba" class="d_iblock hov_color">Why Choose The Oakwood Resorts for Wildlife Safari in Tadoba</a></h3>
                                    
                                    <p class="fs_15 lh_26 mb_15">Tadoba Andhari Tiger Reserve, nestled in the heart of Maharashtra...</p>
                                    <div class="btn-box text-center">
                                        <a href="why-choose-the-oakwood-resorts-in-tadoba" class="theme-btn btn-one">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
        </div>

        {/* Blog 3  */}
        <div class="col-lg-4 col-md-6 col-sm-12 news-block mt-4">
                        <div class="news-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <div class="inner-box p_relative d_block b_radius_5 b_shadow_7 bg_white">
                                <figure class="p_relative d_block"><a href="what-to-expect-from-the-oakwood-resorts-in-tadoba"><img src="assets/images/blogs/What-to-Expect-from-The-Oakwood-Resorts-1.jpg" alt="blogs-image"/></a></figure>
                                <div class="lower-content p_relative d_block pl_40 pt_35 pr_30 pb_40">
                                    <span class="post-date p_relative d_block fs_15 mb_5">June 25, 2024</span>
                                    <h3 class="p_relative d_block fs_20 lh_28 mb_5 fw_medium"><a href="what-to-expect-from-the-oakwood-resorts-in-tadoba" class="d_iblock hov_color">A Day in the Life at a Tadoba What to Expect from The Oakwood Resorts</a></h3>
                                    
                                    <p class="fs_15 lh_26 mb_15">Tadoba Andhari Tiger Reserve, located in the lush Chandrapur...</p>
                                    <div class="btn-box text-center">
                                        <a href="what-to-expect-from-the-oakwood-resorts-in-tadoba" class="theme-btn btn-one">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
        </div>
        
        {/* Blog 2  */}
        <div class="col-lg-4 col-md-6 col-sm-12 news-block mt-4">
                        <div class="news-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <div class="inner-box p_relative d_block b_radius_5 b_shadow_7 bg_white">
                                <figure class="p_relative d_block"><a href="the-oakwood-resorts–best-for-jungle-safari-and-resort-experience"><img src="assets/images/blogs/oakwood-blog2-1.jpg" alt="blogs-image"/></a></figure>
                                <div class="lower-content p_relative d_block pl_40 pt_35 pr_30 pb_40">
                                    <span class="post-date p_relative d_block fs_15 mb_5">June 21, 2024</span>
                                    <h3 class="p_relative d_block fs_20 lh_28 mb_5 fw_medium"><a href="the-oakwood-resorts–best-for-jungle-safari-and-resort-experience" class="d_iblock hov_color">Exploring Tadoba: The Ultimate Outing Location for Jungle Safari and Resort Experience</a></h3>
                                    
                                    <p class="fs_15 lh_26 mb_15">If you're planning an exciting...</p>
                                    <div class="btn-box text-center">
                                        <a href="the-oakwood-resorts–best-for-jungle-safari-and-resort-experience" class="theme-btn btn-one">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
        </div>

        {/* Blog 1  */}
        <div class="col-lg-4 col-md-6 col-sm-12 news-block mt-4">
                <div class="news-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                    <div class="inner-box p_relative d_block b_radius_5 b_shadow_7 bg_white">
                        <figure class="p_relative d_block"><a href="the-oakwood-resorts-budget-friendly-resorts-in-tadoba"><img src="assets/images/blogs/Budget-Friendly-Resorts-in-Tadoba-Wildlife-Adventures-on-a-Budget.jpg" alt="blogs-image"/></a></figure>
                        <div class="lower-content p_relative d_block pl_40 pt_35 pr_30 pb_40">
                            <span class="post-date p_relative d_block fs_15 mb_5">June 13, 2024</span>
                            <h3 class="p_relative d_block fs_20 lh_28 mb_5 fw_medium"><a href="the-oakwood-resorts-budget-friendly-resorts-in-tadoba" class="d_iblock hov_color">Budget-Friendly Resorts in Tadoba: Wildlife Adventures on a Budget</a></h3>
                            
                            <p class="fs_15 lh_26 mb_15">Nestled in the heart of Maharashtra, Tadoba Andhari National Park is a...</p>
                            <div class="btn-box text-center">
                                <a href="the-oakwood-resorts-budget-friendly-resorts-in-tadoba" class="theme-btn btn-one">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
        </div>

            
        </div>
    </div>
</section>
  )
}
