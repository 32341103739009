import React from 'react'

export const ReturnRefund = () => {
  return (
    <div>

      {/* <!-- Page Title --> */}
      <section
        className="page-title p_relative pt_150 pb_180 centred page-title-height"
        style={{
          backgroundImage:
            "url(" + "assets/images/resource/pench-weekend-package.jpg" + ")",
        }}
      >
        <div
          className="pattern-layer p_absolute"
          style={{
            backgroundImage: "url(" + "assets/images/shape/shape-2.png" + ")",
          }}
        ></div>
        <div className="auto-container">
          <div className="content-box">
            <h1 className="d_block fs_55 lh_70 mb_20 color_white">Refund & Return Policy</h1>
            <p className="d_block fs_20 lh_30 color_white">
              {/* Cupidatat non proident */}
            </p>
          </div>
        </div>
      </section>
      {/* <!-- End Page Title --> */}

      <div className="pt_50 pl_100 pb_30 lh_40 pr_60 text-justify">
        <h3 className='fw_medium'>PAYMENT TERMS POLICY</h3>
        <p>
          1. A pre-authorisation will be taken on your credit card under the name booking is made for the total stay on your arrival. Early check-in and late check-out are chargeable as per the Hotel policy. It is mandatory for guests to present valid photo identification at the time of check-in. Such documents can be either an Indian Aadhaar Card, Indian Driver’s License, Indian Voter ID card or Indian or International passport. PAN cards are not accepted. Hotel reserves the right to refuse check-in in the absence of a valid identification document.
        </p>
        <p>
          2. Payment can be made using the credit card, payment gateway link, deposit in the bank account or demand draft before the due date.
        </p>
        <p>
          3. Applicable Taxes – Taxes are as applicable at the time of reservation.
        </p>
        <p>
          4. An extension of stay at the Hotel is liable to be offered at rate/offer applicable at the time the extension request is made. In case, the original offer at which the reservation was booked has expired at that time, the hotel is not liable to offer a room at the original rates but at current rates on offer.
        </p>
        <p>
          5. Certain privileged booking rates or special offers are not eligible for cancellation, refund or any change. The Customer is therefore advised to check the room description and any such conditions carefully prior to making a booking. Hotel shall not be liable to cancel or refund any monies or alter any bookings if booking is made under such privileged booking rates or special offers.
        </p>
        <p>
          6. Upon cancellation of booking, the refund of the booking amount will be initiated. The booking amount after deduction of cancellation charges and taxes, as applicable, will be credited into the bank account of the Customer using the same payment mode (i.e. debit card/ credit card/ net-banking and any electronic mode) by which the booking was made by the Customer. The refund process may take 10-15 business days.
        </p>
        <p>
          7. In case the booking amount is paid using credit card, the refund will be processed on the credit card.
        </p>
        <p>
          8. Children up-to 5 Years of age can stay free (cribs subject to availability). Additional charges may be applicable for children between 5 and 12 years. 13 years will be charged as per extra adult rate.
        </p>
        <p>
          9. In keeping with our heightened security procedures, we request you to provide your photo-identity proof while checking-in. Indian Nationals can present any of the following which is mandatory: Passport, Driving License, Voter ID Card, Pan Card. Foreign Nationals are required to present their passport and valid visa.
        </p>
      </div>
    </div>
  )
}
