import React from 'react'

export const PrivacyPolicy = () => {
  return (
    <div>
      {/* <!-- Page Title --> */}
      <section
        className="page-title p_relative pt_150 pb_180 centred page-title-height"
        style={{
          backgroundImage:
            "url(" + "assets/images/resource/pench-weekend-package.jpg" + ")",
        }}
      >
        <div
          className="pattern-layer p_absolute"
          style={{
            backgroundImage: "url(" + "assets/images/shape/shape-2.png" + ")",
          }}
        ></div>
        <div className="auto-container">
          <div className="content-box">
            <h1 className="d_block fs_55 lh_70 mb_20 color_white">Privacy Policy</h1>
            <p className="d_block fs_20 lh_30 color_white">
              {/* Cupidatat non proident */}
            </p>
          </div>
        </div>
      </section>
      {/* <!-- End Page Title --> */}

      <div className="pt_50 pl_100 pb_30 lh_40 pr_60 text-justify">
        {/* <h4>Note</h4> */}
        <p>
        1. The Oakwood Resorts welcomes you to its website and looks forward to a meaningful interaction with you. The Oakwood Resorts respects your right to privacy. Any personal information that you share with us, like your name, date of birth, address, marital status, telephone number, credit card particulars and the like, shall be entitled to privacy and kept confidential.
        </p>
        <p>
        2. The Oakwood Resorts assures you that your personal information shall not be used/disclosed by it, save for the purpose of doing the intended business with you, or if required to be disclosed under the due process of law.
        </p>
        <p>
        3. The Oakwood Resorts assures you that in the event of your personal information being shared with its holding company, such sharing of information shall be for the purpose of doing the intended business with you.
        </p>
        <p>
        4. The Oakwood Resorts reserves its rights to collect, analyse and disseminate aggregate site usage patterns of all its visitors with a view to enhancing services to its visitors. This includes sharing the information with its holding company as a general business practice.
        </p>
        <p>
        5. In the course of its business The Oakwood Resorts may hold on-line contests and surveys as permitted by law and it reserves its right to use and disseminate the information so collected to enhance its services to the visitors. This shall also include sharing the information with its holding company as a general business practice.
        </p>
        <p>
        6. If you have any questions or concerns regarding your privacy issues, please do not hesitate to contact The Oakwood Resorts at <a href="mail:theoakwoodresorts@gmail.com">theoakwoodresorts@gmail.com</a> 
        </p>
        <p>
        7. While The Oakwood Resorts assures you that it will do its best to ensure the privacy and security of your personal information, it shall not be responsible in any manner whatsoever for any violation or misuse of your personal information by unauthorised persons consequent to misuse of the internet environment.
        </p>
        <p>
        8. The Oakwood Resorts reserves its rights to revise this privacy policy from time to time at its discretion with a view to making the policy more user friendly.
        </p>
        <p>
        9. In the design of our website, we have taken care to draw your attention to this privacy policy so that you are aware of the terms under which you may decide to share your personal information with us. Accordingly, should you choose to share your personal information with us, The Oakwood Resorts will assume that you have no objections to the terms of this privacy policy.
        </p>
        <p>
        10. When you make a reservation on The Oakwood Resorts hotels.com, you will be asked to enter personal information in order to secure your reservation. This information is
submitted to our booking partner and our Web Hosting provider. The Oakwood Resorts and these service providers WILL NOT sell or distribute any personal information about you or any other individual traveler. The name, address and phone number you provide may be used for marketing or quality assurance purposes for the benefit of The Oakwood Resorts . While you will receive an e-mail confirmation whenever you make a reservation on-line, you will not receive any unsolicited e-mail as a result of making a reservation on our site.
        </p>
        <p>
        11. The The Oakwood Resorts hotels.com site may contain external links that will take you away from The Oakwood Resorts hotels.com to our travel partners. We are not responsible for the privacy practices or the content of these sites.
        </p>
        <p>
        <b>DISCLAIMER:</b> ELECTRONIC TRANSMISSIONS, INCLUDING THE INTERNET, PUBLIC MEDIA, AND ANY USE OF SUCH MEDIA IS PUBLIC AND NOT PRIVATE. INFORMATION RELATED TO OR ARISING FROM SUCH USE IS PUBLIC, OR THE PROPERTY OF THOSE COLLECTING INFORMATION, AND NOT PERSONAL OR PRIVATE INFORMATION.
        </p>
      </div>
    </div>
  )
}
